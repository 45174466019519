import styled from "styled-components";

export const LabelStyled = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font-size: ${props => props.large ? "16px" : "12px"};
  line-height: 1.5;
  color: ${props => props.large ? "#333" : "#cbcbcb"};
  width: 100%;
  margin-bottom: ${props => props.margin ? props.margin : "0"};

  &.checkbox {
    flex-direction: row;
    align-items: center;
    gap: 16px;

    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      max-width: 24px;
      height: 24px;
      background: #fff;
      border: 1px solid #000;
      border-radius: 50%;
      background-position: center;
      transition: background 0.2s ease;
    }

    &.active {
      &::before {
        background: url("/assets/images/apply-icon.svg") no-repeat center, #ffcb20;
      }
    }
  }

  button {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    background: #fff;
    text-decoration: underline;
  }

  &.upload {
    display: inline;
    font-weight: bold;
    text-decoration: underline;
  }

  .error-message {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    font-size: 10px;
    color: red;
  }
`;
