import styled from "styled-components";

export const ParagraphStyled = styled.p`
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #333;

  &.bold, .bold {
    font-weight: bold;
  }

  &.italic, .italic {
    font-style: italic;
  }

  @media (max-height: 640px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
