import React from "react"
import { InputStyled } from "./Input.style"

export default class Input extends React.Component {
    render() {
        return (
            <InputStyled {...this.props} />
        )
    }
}
