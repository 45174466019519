import styled from "styled-components";

export const BackButtonStyled = styled.button`
  position: fixed;
  top: 8px;
  left: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  z-index: 100;
  border-radius: 50%;
  transition: border-color 0.2s ease;

  &.scroll {
    border-color: #000;
  }
`;
