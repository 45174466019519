import React from "react";
import PropTypes from "prop-types";
import AppContext from "../../../contexts/AppContext";

export default class ShareContainerView extends React.Component {
  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return <div className="btns-container">
      {Object.keys(this.props.providers).map((provider) => <button
        key={provider}
        hidden={!this.props.providers[provider]}
        className={"btn-" + provider}
        onClick={() => this.props.onShare(provider)}
      />)}
    </div>;
  }
}

ShareContainerView.contextType = AppContext;
ShareContainerView.propTypes = {
  hidden: PropTypes.bool,
  onShare: PropTypes.func.isRequired,
};