import styled from "styled-components";

export const ModalContainerStyled = styled.div`
  padding: 0 16px;
`

export const ModalBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 24px;
  width: 100%;
  box-sizing: border-box;
  padding: 32px 24px;
  margin-bottom: ${props => props.margin ? "8px" : "24px"};

  p {
    margin-bottom: 9px;
    margin-top: 23px;
  
    span {
      font-weight: bold;
    }
  }
`

export const ModalStarIconDefault = styled.div`
  position: absolute;
  svg {
    fill: #cbcbcb;
    width: 100%;
    height: 100%;
  }


  .icon-star1 {
    width: 11px;
    left: 40px;
    top: -20px;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  .icon-star2 {
    width: 11px;
    left: -24px;
    top: 14px;
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  .icon-star3 {
    width: 16px;
    left: 15px;
    bottom: -23px;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  .icon-star4 {
    width: 16px;
    right: 40px;
    top: -20px;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  .icon-star5 {
    width: 26px;
    right: -20px;
    bottom: -16px;
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
`

export const ModalStarIcon1 = styled(ModalStarIconDefault)`
  width: 11px;
  left: 22px;
  top: -28px;
  transform: rotate(15deg);
`

export const ModalStarIcon2 = styled(ModalStarIconDefault)`
  width: 11px;
  left: -30px;
  top: 2px;
  transform: rotate(-15deg);
`

export const ModalStarIcon3 = styled(ModalStarIconDefault)`
  width: 16px;
  left: 0px;
  bottom: -30px;
  transform: rotate(-30deg);
`

export const ModalStarIcon4 = styled(ModalStarIconDefault)`
  width: 16px;
  right: 16px;
  top: -30px;
  transform: rotate(15deg);
`

export const ModalStarIcon5 = styled(ModalStarIconDefault)`
  width: 24px;
  right: -30px;
  bottom: -26px;
  transform: rotate(-5deg);
`