import React from "react";
import { LabelStyled } from "./Label.styled";

export default class Label extends React.Component {
    render() {
        return (
            <LabelStyled {...this.props}>{this.props.children}</LabelStyled>
        )
    }
}
