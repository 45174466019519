import React from "react";
import PropTypes from "prop-types";
import {promisifyImage} from "../../../../utils/image";
import { debounce } from "../../../../utils/etc";
import clientStorage from "../../../../utils/client-storage";
import {ViewContainerStyled, ViewHiddenImageStyled, ViewResultImageStyled } from "./ImageWatermarkView.style";
import Watermark from "../../../../components/Watermark/Watermark";

const watermarkInitialPadding = 0.05;

export default class ImageWatermarkView extends React.Component {

  state = {
    isLoaded: false,
    imageRealWidth: 0,
    imageRealHeight: 0,
    imageWidth: 0,
    imageHeight: 0,
    imageX: 0,
    imageY: 0,
    watermarkIsLoaded: false,
    watermarkWidth: 0,
    watermarkHeight: 0,
    watermarkX: 0,
    watermarkY: 0,
    tooltipIsHidden: true,
  };

  isResized = false;
  containerRef = null;
  hiddenImageRef = null;
  watermarkImageRef = null;

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    clearTimeout(this.tooltipHideTimer);
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => debounce("ImageWatermarkView_handleWindowResize", 100, () => {
    this.isResized = true;
    this.handleImageLoaded();
  });

  handleChanges() {
    if (this.props.onChange && this.state.isLoaded) {
      const xp = (this.state.watermarkX - this.state.imageX) / this.state.imageWidth;
      const yp = (this.state.watermarkY - this.state.imageY) / this.state.imageHeight;

      this.props.onChange({
        x: this.state.imageRealWidth * xp,
        y: this.state.imageRealHeight * yp,
        width: Math.round(this.state.imageRealWidth * this.props.watermarkSize),
      });

      clientStorage.setWatermarkPosition(
        this.state.watermarkX,
        this.state.watermarkY,
        this.state.imageRealWidth,
        this.state.imageRealHeight,
        Date.now()
      );
    }
  }

  handleImageLoaded = () => {
    this.tooltipHideTimer = setTimeout(() => {
      this.setState({tooltipIsHidden: true});
    }, 10_000);

    const containerRect = this.containerRef.getBoundingClientRect();
    const bounds = containRectToBounds(this.hiddenImageRef, containerRect);
    const nextState = {
      isLoaded: true,
      imageRealWidth: this.hiddenImageRef.width,
      imageRealHeight: this.hiddenImageRef.height,
      imageWidth: bounds.width,
      imageHeight: bounds.height,
      imageX: bounds.x,
      imageY: bounds.y,
    };

    this.loadWatermark(nextState, bounds);
  };

  loadWatermark = (nextState, bounds, imageWidth, imageHeight) => {
    promisifyImage(this.props.watermarkUrl)
      .then((watermarkImage) => {
        const padding = nextState.imageWidth * watermarkInitialPadding;

        nextState.watermarkIsLoaded = true;
        nextState.watermarkWidth = bounds.width * this.props.watermarkSize;
        nextState.watermarkHeight = (nextState.watermarkWidth / watermarkImage.width) * watermarkImage.height;
        nextState.watermarkX = ((nextState.imageX + nextState.imageWidth) - nextState.watermarkWidth) - padding;
        nextState.watermarkY = ((nextState.imageY + nextState.imageHeight) - nextState.watermarkHeight) - padding;

        this.initialWatermarkX = nextState.watermarkX;
        this.initialWatermarkY = nextState.watermarkY;

        const storedWatermarkPosition = clientStorage.getWatermarkPosition();
        const storedWatermarkPositionIsSatisfied = (storedWatermarkPosition.storedAt + 60_000) >= Date.now()
          && (storedWatermarkPosition.imageWidth/storedWatermarkPosition.imageHeight === nextState.imageRealWidth/nextState.imageRealHeight);

        if (storedWatermarkPositionIsSatisfied && false) {
          nextState.watermarkX = storedWatermarkPosition.x;
          nextState.watermarkY = storedWatermarkPosition.y;
          nextState.tooltipIsHidden = true;
        }

        if (this.isResized) {
          this.isResized = false;
          const px = (this.state.watermarkX - this.state.imageX) / this.state.imageWidth;
          const py = (this.state.watermarkY - this.state.imageY) / this.state.imageHeight;

          nextState.watermarkX = nextState.imageX + nextState.imageWidth * px;
          nextState.watermarkY = nextState.imageY + nextState.imageHeight * py;
        }

        this.setState(nextState, () => {
          this.props.onLoaded();
          this.handleChanges();
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState(nextState, () => {
          this.props.onError(err);
          this.handleChanges();
        });
      });
  }

  handleImageLoadError = () => {
    this.props.onError(new Error("Failed to load result"));
  }

  handleTouchStart = (e) => {
    e.preventDefault();
    return;

    if (e.target === this.watermarkImageRef) {
      this.isDrag = true;
      this.props.onCaptureStart();

      if (e.type.indexOf("mouse") >= 0) {
        this.pointerX = e.clientX;
        this.pointerY = e.clientY;
      } else {
        this.pointerX = e.touches[0].clientX;
        this.pointerY = e.touches[0].clientY;
      }
    }
  };

  handleTouchEnd = (e) => {
    this.isDrag = false;
    this.props.onCaptureEnd();

    const bottomY = this.state.imageY + this.state.imageHeight;
    const watermarkBottomY = this.state.watermarkY + this.state.watermarkHeight;

    if (this.state.watermarkY > bottomY) {
      this.props.onRemoveIntent();
      this.setState({
        watermarkX: this.initialWatermarkX,
        watermarkY: this.initialWatermarkY,
      }, this.handleChanges);
    } else if (watermarkBottomY > bottomY) {
      this.setState({
        watermarkY: bottomY - this.state.watermarkHeight,
      }, this.handleChanges);
    }
  };

  handleTouchMove = (e) => {
    if (!this.isDrag) {
      return;
    }

    let ex, ey;
    if (e.type.indexOf("mouse") >= 0) {
      ex = e.clientX;
      ey = e.clientY;
    } else {
      ex = e.touches[0].clientX;
      ey = e.touches[0].clientY;
    }

    let nx = this.state.watermarkX + (ex - this.pointerX);
    nx = Math.max(nx, this.state.imageX);
    nx = Math.min(nx, (this.state.imageX + this.state.imageWidth) - this.state.watermarkWidth);

    let ny = this.state.watermarkY + (ey - this.pointerY);
    ny = Math.max(ny, this.state.imageY);
    // ny = Math.min(ny, (this.state.imageY + this.state.imageHeight) - this.state.watermarkHeight);

    this.setState({
      watermarkX: nx,
      watermarkY: ny,
      tooltipIsHidden: true,
    }, this.handleChanges);

    this.pointerX = ex;
    this.pointerY = ey;
  };

  render() {
    const imageStyles = {
      width: this.state.imageWidth,
      height: this.state.imageHeight,
      top: this.state.imageY,
      left: this.state.imageX,
    };

    const watermarkHolderStyles = {
      width: this.state.watermarkWidth,
      height: this.state.watermarkHeight,
      top: this.state.watermarkY,
      left: this.state.watermarkX,
    }

    const watermarkStyles = {
      width: this.state.watermarkWidth,
      height: this.state.watermarkHeight,
    }

    return <ViewContainerStyled
      ref={(ref) => this.containerRef = ref}
      onMouseDown={this.handleTouchStart}
      onMouseUp={this.handleTouchEnd}
      onMouseMove={this.handleTouchMove}
      onTouchStart={this.handleTouchStart}
      onTouchEnd={this.handleTouchEnd}
      onTouchMove={this.handleTouchMove}>

      <ViewHiddenImageStyled
        ref={(ref) => this.hiddenImageRef = ref}
        alt="Hidden"
        src={this.props.imageUrl}
        onLoad={this.handleImageLoaded}
        onError={this.handleImageLoadError}
      />

      {this.state.isLoaded && <ViewResultImageStyled
        widthImage={imageStyles.width}
        heightImage={imageStyles.height}
        topImage={imageStyles.top}
        leftImage={imageStyles.left}
        alt="Result"
        src={this.props.imageUrl}
      />}

      {this.state.watermarkIsLoaded && this.props.watermarkIsShown && <Watermark
        refImg={(ref) => this.watermarkImageRef = ref}
        srcImg={this.props.watermarkUrl}
        stylesWatermark={watermarkHolderStyles}
        stylesImg={watermarkStyles}
        isTooltipHidden={this.state.tooltipIsHidden} />}
    </ViewContainerStyled>;
  }
}

ImageWatermarkView.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  watermarkIsShown: PropTypes.bool.isRequired,
  watermarkUrl: PropTypes.string.isRequired,
  watermarkSize: PropTypes.number.isRequired,
  onLoaded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveIntent: PropTypes.func.isRequired,
  onCaptureStart: PropTypes.func.isRequired,
  onCaptureEnd: PropTypes.func.isRequired,
};

function containRectToBounds(rect, bounds) {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const box = {};

  if (rectRatio > boundsRatio) {
    box.width = bounds.width;
    box.height = rect.height * (bounds.width / rect.width);
  } else {
    box.width = rect.width * (bounds.height / rect.height);
    box.height = bounds.height;
  }

  box.x = (bounds.width - box.width) / 2;
  box.y = (bounds.height - box.height) / 2;

  return box;
}
