import styled from "styled-components";

export const TextareaStyled = styled.textarea`
    padding: 12px 16px;
    width: 100%;
    border-radius: 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #000;
    caret-color: #ffcb20;
    resize: none;
    min-height: 50px;

    &:invalid {
        border-color: red;
    }

    &[type=checkbox], &[type=file] {
      position: absolute;
      opacity: 0;
      z-index: -1;
      visibility: hidden;
    }
`;
