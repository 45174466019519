import React from "react";
import i18n from "../../i18n";
import { WatermarkContainerStyled, WatermarkImgStyled, WatermarkTextStyled, WatermarkTooltipStyled } from "./Watermark.style";

export default class Watermark extends React.Component {
    render() {
        return (
            <WatermarkContainerStyled
                widthWatermark={this.props.stylesWatermark.width}
                heightWatermark={this.props.stylesWatermark.height}
                topWatermark={this.props.stylesWatermark.top}
                leftWatermark={this.props.stylesWatermark.left}>
                <WatermarkImgStyled
                    ref={this.props.refImg}
                    src={this.props.srcImg}
                    widthWatermarkImg={this.props.stylesImg.width}
                    heightWatermarImg={this.props.stylesImg.height} 
                    alt="Watermark"/>
                <WatermarkTooltipStyled hidden={this.props.isTooltipHidden}>
                    <WatermarkTextStyled dangerouslySetInnerHTML={{__html: i18n.t("result_page__watermark_tooltip")}} />
                </WatermarkTooltipStyled>
            </WatermarkContainerStyled>
        )
    }
}
