import React from "react";
import AppContext from "../../../contexts/AppContext";
import instagramParseApiHelper from "../../../helpers/instagram-parse-api.helper";
import FeedView from "./FeedView";
import ConfigView from "./ConfigView/ConfigView";
import processingManager from "../../../photolab/ProcessingManager";
import { generatePath } from "react-router";
import routes from "../../../routes";
import { fileToJson } from "../../../utils/webview";
import Processing from "../../../photolab/Processing";
import Creative from "../../../photolab/Creative";
import FileChooseButton from "../../../components/FileChooseButton";
import uploadHandler from "../../../utils/upload.handler";
import SuggestionsView from "./SuggestionsView";
import * as api from "../../../utils/api";
import CreativeConfig from "../../../photolab/CreativeConfig";
import feedConfigHelper from "../../../helpers/feed-config.helper";
import clientStorage from "../../../utils/client-storage";
import i18n from "../../../i18n";
import MainTitle from "../../../components/MainTitle/MainTitle";
import BackButton from "../../../components/BackButton/BackButton";
import Container from "../../../components/Container/Container";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import LoadMoreButton from "../../../components/LoadMoreButton/LoadMoreButton";
import Paragraph from "../../../components/Paragraph/Paragraph";

const viewMode = {
  profiles: "profiles",
  images: "images",
  configs: "configs",
};

export default class CreatePage extends React.Component {

  state = {
    feedConfig: [],
    profile: {},
    feed: {},
    feedShowMoreIsVisible: false,
    searchError: null,
    uploadError: null,
    selectedImage: null,
    selectedConfig: null,
    viewMode: null,
    numberOfProfilesShown: 10,
  };

  componentDidMount() {
    const storedInstagramProfiles = clientStorage.getInstagramProfiles();
    const isExpired = !storedInstagramProfiles.created_at || storedInstagramProfiles.created_at + 86400000 <= Date.now();

    const instagramProfilesPromise = !isExpired
      ? Promise.resolve()
      : api.getTopInstagramProfiles()
        .then((result) => {
          clientStorage.setInstagramProfiles(result);
        });

    Promise.all([
      feedConfigHelper.getConfig("tcoinfullsize"),
      instagramProfilesPromise,
    ]).then(([feedConfig]) => {
      this.setState({
        feedConfig
      }, this.context.hideLoader);
    }).catch((err) => {
      console.error(err);
    });

    if (processingManager.processing) {
      this.handleSelectedProfile({ username: processingManager.processing.extra.ig_profile_username });
      processingManager.clear();
    } else {
      this.setState({ viewMode: viewMode.profiles });
    }
  }

  handleSelectedProfile = (profile) => {
    this.context.showLoader();

    instagramParseApiHelper
      .getProfileInfo(profile.username)
      .then((result) => {
        if (result.feed.items.length === 0) {
          throw new Error("Not found");
        }

        this.setState({
          viewMode: viewMode.images,
          profile: result.profile,
          feed: result.feed,
          feedShowMoreIsVisible: !!result.feed.max_id,
          searchError: null,
        }, this.context.hideLoader);
      })
      .catch((err) => {
        console.error(err);

        this.setState({
          searchError: "Not found",
        }, this.context.hideLoader);
      });
  }

  handleShowMoreClick = () => {
    this.context.showLoader();

    instagramParseApiHelper
      .getFeed(this.state.profile.userId, this.state.feed.max_id)
      .then((result) => {
        const feed = this.state.feed;

        feed.max_id = result.feed.max_id
        feed.items.push(...result.feed.items);

        this.setState({
          feed: feed,
          feedShowMoreIsVisible: !!feed.max_id,
        }, this.context.hideLoader)
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          feedShowMoreIsVisible: false,
        }, this.context.hideLoader);
      });
  }

  handleShowMoreProfiles = () => {
    this.setState({
      searchError: null,
      numberOfProfilesShown: this.state.numberOfProfilesShown + 10,
    });
  }

  handleSelectedFeedImage = (image) => {
    this.context.showLoader();

    api.storeImage(image.url)
      .then((res) => {
        this.setState({
          selectedImage: { ...fileToJson(res.image_url), isCeleb: true },
          viewMode: viewMode.configs,
        }, this.context.hideLoader);
      })
      .catch((error) => {
        console.error(error);
        this.context.hideLoader();
      });
  }

  handleFileSelected = (file) => {
    this.context.showLoader();

    uploadHandler(file)
      .then((image) => {
        this.setState({
          selectedImage: image,
          viewMode: viewMode.configs,
        }, this.context.hideLoader);
      })
      .catch((error) => {
        this.setState({
          uploadError: error.message,
        }, this.context.hideLoader);
      });
  }

  handleSelectedConfig = (config) => {
    this.context.showLoader(() => {
      this.setState({
        selectedConfig: config,
      }, this.startProcessing);
    });
  }

  startProcessing = () => {
    const creative = new Creative();

    if (this.state.selectedConfig) {
      const config = feedConfigHelper.transformConfig(this.state.selectedConfig)
        .setGroup(this.state.selectedConfig.group);

      creative
        .configureByConfig(config)
        .setAsSelected(true);
    } else {
      creative
        .configureByConfig(new CreativeConfig("origin", "origin", "dummy"))
        .setFile("raw", this.state.selectedImage.url)
        .markAsProcessed(this.state.selectedImage.url)
        .setAsSelected(true);
    }

    const processing = new Processing();
    processing.setId(Date.now());
    processing.setFile(this.state.selectedImage);
    processing.setGroups([creative.group]);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setExtra(Processing.EXTRA_IG_PROFILE_USERNAME, this.state.profile.username)
    processing.addCreative(creative);

    processingManager.clear();
    processingManager.setProcessing(processing);

    this.props.history.replace(generatePath(routes.BOUNTY_PROCESSING));
  };

  handleSkipProcessing = () => {
    this.startProcessing();
  }

  handleBackClick = () => {
    const { history } = this.props;
    if (this.state.viewMode === viewMode.configs) {
      this.setState({
        viewMode: viewMode.images,
      })
      this.renderImagesMode()
    } else if (this.state.viewMode === viewMode.images) {
      this.setState({
        viewMode: viewMode.profiles,
      })
      this.renderProfilesMode()
    } else {
      history.push('/bounty')
    }
  };

  renderProfilesMode = () => {
    const { items: storedProfiles } = clientStorage.getInstagramProfiles();
    const profiles = storedProfiles.slice(0, this.state.numberOfProfilesShown);

    return <Container>
      <FlexWrap direction="column" gap="24">
        <BackButton onClick={this.handleBackClick} />
        <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_celebrity_heading") }} />
        <SuggestionsView
          profiles={profiles}
          onSelected={this.handleSelectedProfile}
        />
        <LoadMoreButton
          hidden={profiles.length >= storedProfiles.length}
          onClick={this.handleShowMoreProfiles}>
          {i18n.t("bounty.choose_celebrity_refresh_button")}
        </LoadMoreButton>
        {this.state.searchError && <p>ERROR: {this.state.searchError}</p>}
      </FlexWrap>
    </Container>;
  }

  renderImagesMode = () => {
    const feed = this.state.feed.items || [];

    return <Container>
      <FlexWrap direction="column" gap="24">
        <BackButton onClick={this.handleBackClick} />
        <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_image_heading") }} />
        {/*
      {this.state.uploadError && <p>{this.state.uploadError}</p>}
      {this.state.profile.username && <p>username: {this.state.profile.username}</p>}
      {this.state.profile.followersCount && <p>followers: {this.state.profile.followersCount}</p>} */}

        <Paragraph>
          {i18n.t("bounty.choose_image_feed_text")} <FileChooseButton
            onFileSelected={this.handleFileSelected}
          >
            {i18n.t("bounty.choose_image_upload_button")}
          </FileChooseButton>
        </Paragraph>
        <FeedView
          hidden={feed.isEmpty()}
          feed={feed}
          onSelected={this.handleSelectedFeedImage}
        />
        <LoadMoreButton
          hidden={!this.state.feedShowMoreIsVisible}
          onClick={this.handleShowMoreClick}>
          {i18n.t("bounty.choose_image_feed_refresh_button")}
        </LoadMoreButton>
      </FlexWrap>
    </Container>;
  }

  renderConfigsMode = () => {
    return <Container>
      <BackButton onClick={this.handleBackClick} />
      <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_config_heading") }} />

      <p
        hidden={this.state.selectedImage.isCeleb}
        onClick={(e) => {
          e.stopPropagation();
          if (e.target.tagName.toLowerCase() === "a") {
            this.handleSkipProcessing();
          }
        }}
        dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_config_text") }}
      />

      <button
        hidden={this.state.selectedImage.isCeleb}
        onClick={this.handleSkipProcessing}>
        {i18n.t("bounty.choose_config_use_original_button")}
      </button>

      <ConfigView
        config={this.state.feedConfig}
        onSelected={this.handleSelectedConfig}
      />
    </Container>;
  }

  render() {
    return <main className="create-page">
      {this.state.viewMode === viewMode.profiles && this.renderProfilesMode()}
      {this.state.viewMode === viewMode.images && this.renderImagesMode()}
      {this.state.viewMode === viewMode.configs && this.renderConfigsMode()}
    </main>;
  }
}

CreatePage.contextType = AppContext;
