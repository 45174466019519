import React from "react";
import {ConfigViewColumnsStyled, ConfigViewColumnStyled, ConfigViewBtnStyled, ConfigViewImgStyled } from "./ConfigView.style";

function arrayChunk(arr, chunks) {
  return arr.reduce((acc, n, i) => {
    return (acc[i % chunks] = acc[i % chunks] || []).push(n), acc}, []);
}

export default class ConfigView extends React.Component {

  handleClick = (config) => {
    this.props.onSelected(config);
  }

  render() {
    return <ConfigViewColumnsStyled>
      {arrayChunk(this.props.config, 2).map((arr, i) => {
        return <ConfigViewColumnStyled key={i}>
          {arr.map((item) => {
            return <ItemView
              key={item.id}
              config={item}
              onClick={this.handleClick}
            />})}
        </ConfigViewColumnStyled>}
      )}
    </ConfigViewColumnsStyled>
  }
}

function ItemView(props) {
  const {
    config,
    isActive,
    onClick,
  } = props;

  const ItemViewHeight = config.result_size.height / config.result_size.width * 100

  return <ConfigViewBtnStyled
    $height={ItemViewHeight}
    $activeStyle={isActive} 
    onClick={() => onClick(config)}>
    <ConfigViewImgStyled src={config.result_url} alt="." />
  </ConfigViewBtnStyled>;
}
