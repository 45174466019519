import styled from "styled-components";
import Paragraph from "../../../components/Paragraph/Paragraph";

export const SuggectionsViewListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SuggectionsViewListItemStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const SuggectionsViewListItemImageContainerStyled = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
`;

export const SuggectionsViewListItemImageStyled = styled.img`
  width: 100%;
  height: 100%;;
  border-radius: 50%;
  object-fit: cover;
`;

export const SuggectionsViewListItemParagraphStyled = styled(Paragraph)`
  color: #cbcbcb;
  font-size: 12px;

  &::before {
    content: "•";
    margin-right: 8px;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }
`;
