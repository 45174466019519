import React from "react";
import Container from "../../../../components/Container/Container";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import BackButton from "../../../../components/BackButton/BackButton";
import i18n from "../../../../i18n";
import { List, ListItem, Tip } from "./TermsView.style";
import Button from "../../../../components/Button/Button";
import FlexWrap from "../../../../components/FlexWrap/FlexWrap";

export default class TermsView extends React.Component {
  state = {
    rules_list: []
  };

  componentDidMount() {
    const list = [];

    for (let i = 1; i <= 6; i++) {
      list.push(i18n.t(`bounty.rules_rule_${i}`))
    };

    this.setState({ rules_list: list });
  }

  render() {
    const { rules_list } = this.state;
    const { backClick, nextClick } = this.props;

    return (
      <Container>
        <FlexWrap direction="column" gap={24} flex={1}>
          <BackButton onClick={backClick} />
          <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.rules_heading") }} primary />
          <Tip>
            <p className="bold">Please don’t rush into posting without reading the rules.</p>
            <p>Following every point of them brings your closer to getting the coins.</p>
          </Tip>
          <List>
            {rules_list.map((item, i) => (
              <ListItem dangerouslySetInnerHTML={{ __html: item }} key={i} />
            ))}
          </List>
          <Button onClick={nextClick}>{i18n.t("bounty.rules_start_button")}</Button>
        </FlexWrap>
      </Container>
    )
  }
}
