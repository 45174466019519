module.exports = {
  "feed_page__default_banner_how_to_buy": "Learn more",
  "feed_page__default_banner_message": "a Cool <br /><span>Cryptocurrency</span><br />aimed at good<br />deeds!",
  "feed_page__default_banner_submessage": "This is a photo editing app for regular <span>toon</span> lovers and for the holders of <span>ToonCoin</span> –",

  "feed_page__floating_banner_how_to_buy": "Learn more",
  "feed_page__floating_banner_message": "Game-changing cryptocurrency aimed at good deeds.",

  "processing_page__learn_more": "Learn more",

  "result_page__loading": "Loading",
  "result_page__preparing_photo": "Preparing your photo..",
  "result_page__save_and_share": "Save and Share",
  "result_page__watermark_tooltip": "<span>Tap&Drag</span> to move & delete watermark",

  "watermark_removal_modal__title": "Feel free to remove the watermark.",
  "watermark_removal_modal__text1": "Yet, if you decide to keep it, you could help promote ToonCoin – game-changing crypto currency aimed at good deeds!",
  "watermark_removal_modal__text2": "The entire amount of money raised goes to a charity fund!",
  "watermark_removal_modal__notice": "<span className=\"text-bold\">TIP:</span> You can just drag the watermark to the position you want.",
  "watermark_removal_modal__choice": "Your choice?",
  "watermark_removal_modal__confirm": "Remove watermark",
  "watermark_removal_modal__cancel": "Spread the good!",
  "watermark_removal_modal__close": "Close",

  "share_modal__saved_to_gallery": "Saved to gallery!",
  "share_modal__title": "It’s time!",
  "share_modal__text1": "Now send it to <span>your celebrity</span> in a direct message or inspire them to repost this art with your own Instagram story or post.",
  "share_modal__text2": "The entire amount of money raised goes to a charity fund!",
  "share_modal__close": "Close",
  "share_modal__start_again": "Start again",

  "internal_error": "An error occurred...",
  "error__default_message": "Something went wrong. Try again.",
  "error__network_message": "Please check your network connection.",
  "error_overload": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",
  "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

  "try_another_photo": "Change photo",
  "choose_photo": "Choose photo",
  "time_next_try": "⏰ Try again in {{time}}",

  "maskeditor__title": "Refine the selection",
  "maskeditor__subtitle": " (Scroll to zoom, drag with mouse middle button)",

  "btn_undo": "undo",
  "btn_redo": "redo",
  "btn_save": "save",
  "size": "Brush size",

  "bounty": {
    "index_heading": "<span>Mint</span> yourself some <span>crypto coins</span> by spreading the world about our project!",
    "index_text": "Welcome to the <span class='bold'>ToonCoin Bounty</span> concept, where you get rewarded with our coins every time your art gets reposted by a selected celebrity helping even more people pay attention to <span class='bold'>ToON.org<span>.",
    "index_rules_text": "Please don’t rush into posting without reading the rules. Following every point of them brings your closer to getting the coins.",
    "index_ready_text": "Ready to learn more and join?",
    "index_start_button": "I'm in!",
    "index_reward_button": "Claim your reward",

    "rules_heading": "To <span>join</span> and <span>benefit</span> from the Bounty program <span>you should</span>:",
    "rules_rule_1": "Fill in the form and agree to the terms of the program.",
    "rules_rule_2": "Choose a celebrity from our list.",
    "rules_rule_3": "Create an art with the celebrity you have chosen in our app on the devoted page <span class='italic'>(remember to keep the watermark)</span>.",
    "rules_rule_4": "Wait for a repost or motivate the celebrity to repost your art in their post or stories.",
    "rules_rule_5": "Provide the evidence of the repost <span class='italic'>(for stories it is <span class='bold'>vital</span> to share the news while the story is still available)<span>.",
    "rules_rule_6": "When confirmed by our team you will get <span class='bold'>N</span> coins.",
    "rules_start_button": "Got it",

    "signup_form_heading": "<span>Make sure the email address is correct,</span> because your future coins will be connected to it.",
    "signup_form_email_label": "Enter your email:",
    "signup_form_confirm_email_label": "Confirm your email:",
    "signup_form_terms_agreement": "I agree to the <button>terms and conditions</button> of the program.",
    "signup_form_submit_button": "Send",
    "signup_form_email_is_invalid": "Email is invalid",
    "signup_form_email_not_matches": "Email does not matches",
    "signup_form_field_required": "Required",

    "choose_celebrity_heading": "Time to <span>choose</span> your celebrity:",
    "choose_celebrity_refresh_tip": "None of them looks right? Tap refresh for more.",
    "choose_celebrity_refresh_button": "load more",

    "choose_image_heading": "<span>Great choice!</span> Time to select a photo for your art.",
    "choose_image_feed_text": "Choose from instagram feed or",
    "choose_image_feed_refresh_button": "Load more",
    "choose_image_upload_text": "Or upload any photo you like",
    "choose_image_upload_button": "upload photo",

    "choose_config_heading": "Now choose an <span>art style</span> to apply to the photo:",
    "choose_config_text": "Feel free to leave photo as is and <a href=\"#\" class=\"text-decoration text-bold\">add the watermark only</a>.",
    "choose_config_use_original_button": "Leave as it is",

    "download_heading": "Save & Share:",
    "download_button": "Save & Share",

    "call_to_share_saved_text": "saved to gallery",
    "call_to_share_heading": "It’s time!",
    "call_to_share_text": "Now send it to your celebrity in a direct message or inspire them to repost this art with your own Instagram story or post.",
    "call_to_share_button": "OK!",

    "reward_form_desc": "Fill this form after a celebrity reposts your ToON art",
    "reward_form_label_email": "Enter your email address",
    "reward_form_label_wallet_address": "Enter your ethereum waller number",
    "reward_form_label_celebrity_username": "Enter celebrity username",
    "reward_form_label_link": "Enter link to a post (instagram, twitter, ...)",
    "reward_form_label_attachment": "Attach screenshot",
    "reward_form_label_comment": "Enter comment",
    "reward_form_placeholder_celebrity_username": "celebrity username",
    "reward_form_error_email_invalid": "Invalid Email",
    "reward_form_error_wallet_address_invalid": "Invalid wallet address",
    "reward_form_error_celebrity_username_required": "Please, enter username",
    "reward_form_error_link_invalid": "Invalid link",
    "reward_form_error_link_attachment_required": "Please, enter link to a post or attach file",
  },
};
