import styled from "styled-components";

export const ButtonStyled = styled.button`
  font-family: Alphakind;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${props => props.primary ? "#ffcb20" : "#d5c6f8"};
  padding: 0 12px;
  border-radius: 32px;
  border: 1px solid #000;
  box-shadow: 3px 3px 0 0 #000;
  height: 50px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: ${props => props.marginBottom}px;
  transition: background-color .2s ease;

  &:hover {
      background-color: ${props => props.primary ? "#d5c6f8" : "#ffcb20"};
  }

  &.underline {
    font-family: Helvetica, sans-serif;
    padding: 0;
    background: none;
    text-decoration: underline;
    font-weight: 400;
    color: #999;
    border: none;
    box-shadow: none;
    text-transform: unset;
  }

  &.watermark {
    width: ${props => props.watermark && "50px"};
    svg {
      display: ${props => props.watermark ? "block" : "none"};
      width: 18px;
      fill: #000;
    }

    span {
      display: ${props => props.watermark && "none"};
    }
  }
`;
