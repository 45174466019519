import React from "react";
import { LoadMoreButtonStyled } from "./LoadMoreButton.style";

export default class LoadMoreButton extends React.Component {
  render() {
    return (
      <LoadMoreButtonStyled {...this.props}>
        {this.props.children}
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.105 15.37a7.996 7.996 0 0 0 4.264-10.476A8 8 0 0 0 4.892.629a7.995 7.995 0 0 0-4.26 10.477 7.997 7.997 0 0 0 10.473 4.265zM1.102 8.024A6.9 6.9 0 0 1 8 1.125 6.9 6.9 0 0 1 14.9 8.023 6.9 6.9 0 0 1 8 14.922a6.9 6.9 0 0 1-6.898-6.899zm11.04 1.006L8 13.172 3.858 9.029l.761-.761 2.842 2.845v-7.48H8.54v7.48l2.842-2.842.762.758z" fill="#000" fillRule="nonzero" />
        </svg>
      </LoadMoreButtonStyled>
    )
  }
}
