import React from "react";
import {generatePath} from "react-router";
import {saveAs} from "file-saver";
import routes from "../../../routes";
import AppContext from "../../../contexts/AppContext";
import {assetUrl, debounce} from "../../../utils/etc";
import ImageWatermarkView from "./ImageWatermarkView/ImageWatermarkView";
import * as webviewUtils from "../../../utils/webview";
import * as api from "../../../utils/api";
import {promisifyImage} from "../../../utils/image";
import {transformToDownloadUrl} from "../../../utils/creative";
import WatermarkRemovalModal from "./WatermarkRemovalModal";
import ShareModal from "./ShareModal/ShareModal";
import i18n from "../../../i18n";
import {webviewCall} from "../../../utils/webview";
import {hitEvent, hits, logEvent, userEvents} from "../../../utils/log";
import {isWebviewSupportShareByData} from "../../../utils/config.utils";
import processingManager from "../../../photolab/ProcessingManager";
import creativeGroups from "../../../photolab/config/groups";
import ErrorView from "../../../components/ErrorView";
import clientStorage from "../../../utils/client-storage";
import Loading from "../../../components/Loading/Loading";
import Button from "../../../components/Button/Button";
import MainTitle from "../../../components/MainTitle/MainTitle";
import Processing from "../../../photolab/Processing";

const watermarkUrl = assetUrl("assets/watermarks/w3.png");
const watermarkSize = 0.2;

export default class ResultPage extends React.Component {

  state = {
    isReady: false,
    isLoading: true,
    loadingText: i18n.t("result_page__loading"),
    error: null,
    imageUrl: "",
    watermarkIsRemoved: false,
    watermarkIsCaptured: false,
    providers: {
      instagram: false,
      tiktok: false,
      telegram: false,
      facebook: false,
      snapchat: false,
      whatsapp: false,
    },
  };

  constructor(props) {
    super(props);

    this.watermarkData = {x: 0, y: 0};
    this.layoutedMedia = null;
  }

  componentDidMount() {
    logEvent(userEvents.PAGE, {page: "result"});

    document.addEventListener("dragstart", (e) => {
      // disable drag images
      e.preventDefault();
    }, {passive: false});

    document.addEventListener("touchmove", (e) => {
      // disable ios overscroll
      e.preventDefault();
    }, {passive: false});

    logEvent(userEvents.PAGE, {page: "result"});

    if (window.clientConfig.isWebview) {
      setTimeout(() => {
        webviewUtils.webviewCheckInstalledApps((apps) => {
          const providers = {...this.state.providers};

          Object.keys(apps).forEach((app) => {
            if (providers.hasOwnProperty(app) && apps[app]) {
              providers[app] = true;
            }
          });

          providers.tiktok = false;

          this.setState({providers});
        });
      }, 200);
    }

    processingManager.addOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const restoredProcessing = processingManager.restore();
      if (restoredProcessing) {
        try {
          processingManager.start(restoredProcessing);
        } catch (err) {
          console.error(err);

          this.props.history.replace(generatePath(routes.INDEX));
        }
      } else {
        this.props.history.replace(generatePath(routes.INDEX));
      }
    } else {
      this.handleProcessingChanged();
    }
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  handleProcessingChangedDebug = () => {
    if (window.appConfig.isDebug) {
      const cloned = JSON.parse(processingManager.processing.toJSON(true));
      console.info("ResultPage::handleProcessingChanged", cloned);
    }
  };

  handleProcessingChanged = () => debounce("ResultPage_handleProcessingChanged", 100, () => {
    const processing = processingManager.processing;

    const selectedCreative = processing.creatives
      .filter((c) => c.isSelected && c.group !== creativeGroups.COMMON)
      .first();

    const imageUrl = selectedCreative.getFile("raw");

    this.context.hideLoader();

    this.setState({
      imageUrl,
      isReady: true,
    });
  });

  handleImageLoaded = () => {
    this.setState({isLoading: false});
  };

  handleError = (error) => {
    let message = error.message;
    if (!message || (typeof message === "string" && message.length === 0)) {
      message = i18n.t("error__default_message");
    }

    if (window.clientConfig.isWebview) {
      webviewCall("error", {
        message: encodeURIComponent(message),
      });
    } else {
      this.setState({
        error, isLoading: false
      });
    }
  };

  handleImageWatermarkViewChanged = (data) => {
    this.layoutedMedia = null;
    this.watermarkData = data;
  };

  handleSaveButtonClick = () => {
    if (this.state.watermarkIsCaptured) {
      return;
    }

    this.setState({isMuted: true});

    if (this.layoutedMedia) {
      this.save(this.layoutedMedia);
    } else {
      this.setState({
        isLoading: true,
        loadingText: i18n.t("result_page__preparing_photo")
      }, () => {
        this.layout().then((mediaData) => {
          this.layoutedMedia = mediaData;
          this.setState({isLoading: false});
          this.save(mediaData);
        }).catch((err) => {
          this.handleError(err);
        });
      });
    }
  };

  layoutImage = () => new Promise((resolve, reject) => {
    Promise.all([
      promisifyImage(transformToDownloadUrl(this.state.imageUrl), true),
      promisifyImage(watermarkUrl, true),
    ]).then(([sourceImage, watermarkImage]) => {
      const canvas = document.createElement("canvas");
      canvas.width = sourceImage.width;
      canvas.height = sourceImage.height;

      const canvasCtx = canvas.getContext("2d");
      canvasCtx.drawImage(sourceImage, 0, 0);
      canvasCtx.drawImage(
        watermarkImage,
        this.watermarkData.x,
        this.watermarkData.y,
        this.watermarkData.width,
        Math.round(watermarkImage.height * (this.watermarkData.width / watermarkImage.width))
      );

      return canvas;
    }).then((canvas) => {
      const extension = this.state.imageUrl
        .split(".")
        .pop()
        .toLowerCase()
        .replace(/jpg/, "jpeg");

      return new Promise((resolve) => canvas.toBlob(resolve, "image/" + extension));
    }).then((blob) => {
      const promises = [
        api.tempImagesUploadFile(blob, "jpeg"),
      ];

      if (isWebviewSupportShareByData()) {
        promises.push(new Promise(function(resolve) {
          const reader = new FileReader();
          reader.onload = () => resolve(new Uint8Array(reader.result));
          reader.readAsArrayBuffer(blob);
        }));
      }

      return Promise.all(promises);
    }).then((results) => {
      console.info("Result URL", results[0]);
      resolve({
        url: results[0],
        data: results.length > 1 ? results[1] : null,
      });
    }).catch(reject);
  });

  layout = () => this.layoutImage();

  save = (media) => {
    hitEvent(hits.RESULT_PAGE_DOWNLOAD_CLICK);
    logEvent(userEvents.DOWNLOAD);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewSaveMedia(media.data || media.url);
    }

    const fileName = media.url.substring(media.url.lastIndexOf('/')+1);

    saveAs(transformToDownloadUrl(media.url), fileName);

    api.saveResult(clientStorage.getClientEmail(), media.url, processingManager.processing.getExtra(Processing.EXTRA_IG_PROFILE_USERNAME))
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.error(err);
      });

    this.context.pushModal(<ShareModal
      key="ResultPage_ShareModal"
      providers={this.state.providers}
      onShare={(provider) => {
        this.share(provider, media);
      }}
      onGoToStart={() => {
        this.props.history.replace(routes.BOUNTY_INDEX);
      }}
    />);
  };

  share = (provider, media) => {
    hitEvent(hits.RESULT_PAGE_DOWNLOAD_CLICK);
    logEvent(userEvents.SHARE, {provider});

    const params = {};
    params.imageUrl = media.url;
    params.hashtag = "#ToonCoin";
    params.no_sound = this.state.hasSound ? 0 : 1;

    switch (provider) {
      case "instagram": {
        params.providers = [1];
        params.ig_direct = 1;
        params.close_after_share = 1;
        break;
      }
      case "telegram": {
        params.providers = [6];
        break;
      }
      case "facebook": {
        params.providers = [2];
        params.url = params.imageUrl;
        break;
      }
      case "snapchat": {
        params.providers = [11];
        break;
      }
      case "whatsapp": {
        params.providers = [3];
        break;
      }
      case "tiktok": {
        params.providers = [12];
        break;
      }
      default: {
        console.error("Unrecognized share provider: " + provider);
      }
    }

    webviewUtils.webviewShare(params, undefined, (data) => {
      console.log("sharetest", data);
      console.log("sharetest", JSON.stringify(data));
    });
  };

  handleWatermarkRemoveIntent = () => {
    this.context.pushModal(<WatermarkRemovalModal
      key="ResultPage_WatermarkRemovalModal"
      onConfirm={this.handleWatermarkRemoveConfirm}
      onCancel={this.handleWatermarkRemoveCancel}
    />);
  };

  handleWatermarkRemoveConfirm = () => {
    this.setState({
      watermarkIsRemoved: true,
    }, () => {
      this.layoutedMedia = {
        url: this.state.imageUrl,
        data: null,
      };
    });
  };

  handleWatermarkRemoveCancel = () => {
    // nothing :)
  };

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});

    this.props.history.replace(routes.UPLOAD, {file});
  };

  handleBackButtonClick = (e) => {
    const config = clientStorage.getLatestSelectedCombo();

    if (config) {
      this.props.history.push(generatePath(routes.FEED), {feedId: config.group})
    } else {
      this.props.history.replace(generatePath(routes.INDEX));
    }
  };

  render() {
    const classNames = ["result-page"];
    this.state.isLoading && classNames.push("-show-loader");


    return <main className={classNames.join(" ")}>
      <Loading message={this.state.loadingText} hidden={!this.state.isLoading} />

      {this.state.error && <ErrorView error={this.state.error} onFileSelected={this.handleFileSelected} />}

      {this.state.isReady && !this.state.error && <>
        <MainTitle dangerouslySetInnerHTML={{__html: i18n.t("bounty.download_heading")}} />

        <ImageWatermarkView
          imageUrl={this.state.imageUrl}
          watermarkIsShown={!this.state.watermarkIsRemoved}
          watermarkUrl={watermarkUrl}
          watermarkSize={watermarkSize}
          onLoaded={this.handleImageLoaded}
          onError={this.handleError}
          onChange={this.handleImageWatermarkViewChanged}
          onRemoveIntent={this.handleWatermarkRemoveIntent}
          onCaptureStart={() => this.setState({watermarkIsCaptured: true})}
          onCaptureEnd={() => this.setState({watermarkIsCaptured: false})}
        />

        <Button 
          primary
          className="watermark"
          watermark={this.state.watermarkIsCaptured}
          onClick={this.handleSaveButtonClick}>
          <span dangerouslySetInnerHTML={{__html: i18n.t("result_page__save_and_share")}} />
          <SvgTrashboxIcon />
        </Button>
      </>}
    </main>;
  }
}

ResultPage.contextType = AppContext;

function SvgTrashboxIcon() {
  return <svg viewBox="0 0 52 60">
    <path d="M38.01 60H13.99c-2.599 0-4.782-1.998-5.07-4.64L4.325 13.042h43.35L43.08 55.36C42.792 58.002 40.609 60 38.01 60zM31.1 0c1.408 0 2.55 1.169 2.55 2.609h15.3c1.353 0 2.462 1.08 2.545 2.443l.005.165c0 1.44-1.142 2.61-2.55 2.61H3.05C1.697 7.826.588 6.745.505 5.381L.5 5.217c0-1.44 1.142-2.608 2.55-2.608h15.3C18.35 1.169 19.492 0 20.9 0h10.2z" fillRule="nonzero" />
  </svg>;
}
