import styled from "styled-components";

export const LandingViewImage = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 8px 0 20px;
  height: 264px;

  img {
    object-fit: contain;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    background: #000;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-height: 640px) {
    height: 200px;
  }
`;
