import styled from "styled-components";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";

export const FeedViewStyled = styled(FlexWrap)`
  place-content: flex-start;
`;

export const FeedViewItemStyled = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);

  &::before {
    padding-top: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  &.active {
    border: 4px solid #ffcb20;
  }

  &.disabled {
    opacity: 0.48;
  }
`;
