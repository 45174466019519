import React from "react";
import AppContext from "../../../contexts/AppContext";
import Label from "../../../components/Label/Label";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import Textarea from "../../../components/Textarea/Textarea";
import i18n from "../../../i18n";

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function isValidUrl(str) {
  let url;

  try {
    url = new URL(str);
  } catch(e) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export default class RewardFormView extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();

    let isValidForm = true;

    const {email, username, walletAddress, link, comment, attachment} = this.context.rewardData;
    const data = {formError: ""};

    if (!isValidEmail(email.value.trim())) {
      data.email = {value: email.value, error: i18n.t("bounty.reward_form_error_email_invalid")};
      isValidForm = false;
    }

    if (!walletAddress.value && (walletAddress.value < 40 || walletAddress.value > 44)) {
      data.walletAddress = {value: walletAddress.value, error: i18n.t("bounty.reward_form_error_wallet_address_invalid")};
      isValidForm = false;
    }

    if (username.value.trim().length === 0) {
      data.username = {value: username.value, error: i18n.t("bounty.reward_form_error_celebrity_username_required")};
      isValidForm = false;
    }

    if (link.value && !isValidUrl(link.value)) {
      data.link = {value: link.value, error: i18n.t("bounty.reward_form_error_link_invalid")};
      isValidForm = false;
    }

    if (!link.value && !attachment.value) {
      data.formError = i18n.t("bounty.reward_form_error_link_attachment_required");
      isValidForm = false;
    }

    if (isValidForm) {
      this.props.onSubmit(
        email.value,
        username.value,
        walletAddress.value,
        link.value,
        comment.value,
        attachment.value
      );
    } else {
      this.context.updateRewardData(data);
    }
  }

  handleTextChange = (e, field) => {
    this.context.updateRewardData({
      formError: null,
      [field]: {
        value: e.target.value,
        error: null,
      }
    });
  }

  handleFileChange = (e) => {
    this.context.updateRewardData({
      formError: null,
      attachment: {
        value: e.target.files[0],
        error: null,
      }
    });
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment/>;
    }

    const {
      formError,
      email,
      username,
      walletAddress,
      link,
      comment,
      attachment
    } = this.context.rewardData;

    return <React.Fragment>
      <p dangerouslySetInnerHTML={{ __html: i18n.t("bounty.reward_form_desc") }} />

      <form onSubmit={this.handleSubmit}>
        <Label margin="16px">
          {i18n.t("bounty.reward_form_label_email")}
          <Input value={email.value} placeholder="example@gmail.com" onChange={(e) => this.handleTextChange(e, "email")} />
          {email.error && <ErrorMessage margin="2px">{email.error}</ErrorMessage>}
        </Label>
        <Label margin="16px">
          {i18n.t("bounty.reward_form_label_wallet_address")}
          <Input value={walletAddress.value} placeholder="0x0000000000000000000000000000000000000000" onChange={(e) => this.handleTextChange(e, "walletAddress")} />
          {walletAddress.error && <ErrorMessage margin="2px">{walletAddress.error}</ErrorMessage>}
        </Label>
        <Label margin="16px">
          {i18n.t("bounty.reward_form_label_celebrity_username")}
          <Input value={username.value} placeholder={i18n.t("bounty.reward_form_placeholder_celebrity_username")} onChange={(e) => this.handleTextChange(e, "username")} />
          {username.error && <ErrorMessage margin="2px">{username.error}</ErrorMessage>}
        </Label>
        <Label margin="16px">
          {i18n.t("bounty.reward_form_label_link")}
          <Input value={link.value} placeholder="https://" onChange={(e) => this.handleTextChange(e, "link")} />
          {link.error && <ErrorMessage margin="2px">{link.error}</ErrorMessage>}
        </Label>
        <Label margin="16px">
          {i18n.t("bounty.reward_form_label_attachment")}
          <Input type="file" visible={true} accept="image/*" onChange={this.handleFileChange} />
          {attachment.error && <ErrorMessage margin="2px">{attachment.error}</ErrorMessage>}
        </Label>
        <Label margin="16px">
          {i18n.t("bounty.reward_form_label_comment")}
          <Textarea value={comment.value} onChange={(e) => this.handleTextChange(e, "comment")} />
          {comment.error && <ErrorMessage margin="2px">{comment.error}</ErrorMessage>}
        </Label>
        <Label margin="16px">
          {formError && <ErrorMessage margin="2px">{formError}</ErrorMessage>}
        </Label>
        <Button primary>Confirm</Button>
      </form>
    </React.Fragment>;
  }
}

RewardFormView.contextType = AppContext;
