import CreativeConfig from "../photolab/CreativeConfig";
import {handlersNames} from "../photolab/handlers";
import Creative from "../photolab/Creative";

const cache = {};

function getUrl(feedId) {
  return `https://tooncoin-web.test.photo-cdn.net/feeds/${feedId}.json`;
}

function configFilter(feedConfig) {
  const enabledTextStyles = [1,2,3,4,7,8,10,11,15,16,17,18,19,21,23,24,26,27,28,38];

  return feedConfig.filter((config) => {
    const isWebProcessingStepExist = config.steps.findIndex((step) => {
      return step.properties && step.properties.web_data;
    }) > -1;

    const isTextStepExist = config.steps.findIndex((step) => {
      const styleId = (step.properties && step.properties.style_id) || 0;

      return step.type === "text" && enabledTextStyles.indexOf(styleId) > -1;
    }) > -1;

    return !isWebProcessingStepExist && !isTextStepExist;
  });
}

function getConfig(feedId) {
  if (cache[feedId]) {
    return Promise.resolve(cache[feedId]);
  }

  return fetch(getUrl(feedId))
    .then((res) => res.json())
    .then(configFilter)
    .then((feedConfig) => {
      cache[feedId] = feedConfig;

      return feedConfig;
    });
}

function transformConfig(config) {
  let content = [
    {src: "@", useAltBody: true},
  ];

  (config.content || []).forEach((item) => {
    if (item.url && item.fixed) {
      content.push({
        src: item.url,
        rect: item.crop ? "(" + item.crop.map((v) => (v).toFixed(2)).join(",") + ")" : "",
        rotation: item.rotation || 0,
        flip: item.flip || 0,
      });
    }
  });

  const steps = config.steps.map((step) => {
    if (step.type !== "template") {
      return null;
    }

    const result = {
      id: step.id,
      images: [],
    };

    (step.intermediate_content || []).forEach((item) => {
      if (item.url && item.fixed) {
        result.images.push({
          src: item.url,
          rect: item.crop ? "(" + item.crop.map((v) => (v).toFixed(2)).join(",") + ")" : "",
          rotation: item.rotation || 0,
          flip: item.flip || 0,
        });
      } else {
        result.images.push({src: "#", useAltBody: true});
      }
    });

    if (result.images.isEmpty()) {
      if (content) {
        result.images = content;
        content = undefined;
      } else {
        result.images.push({src: "#", useAltBody: true});
      }
    }

    return result;
  })
  .filter(Boolean);

  steps.last().setAsFile = "raw";

  return new CreativeConfig("nogroup", config.id, handlersNames.COMBO)
    .setExtra(Creative.EXTRA_COMBO_STEPS, steps);
}

export default {
  getConfig,
  transformConfig
};