import axios from "axios";
import {debounce} from "./etc";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  is_mobile_desktop_mode: window.clientConfig.isWebMobileDesktopMode,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

// --

export const userEvents = {
  PAGE: "page",
  PHOTO_SELECT: "photo_select",
  PHOTO_UPLOADED: "photo_uploaded",
  DOWNLOAD: "download",
  SHARE: "share",
  RECOMMENDED_CLICK: "recommended_click",
  LEARN_MORE_CLICK: "learn_more_click",
  PROCESSING_PROCESSED: "processing_processed",
  PROCESSING_FAILED: "processing_failed",

  CREATIVE_STARTED: "creative_started",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",

  GET_APP_CLICK: "get_app_click",

  FRONTEND_WATERMARK_FAILED: "frontend_watermark_failed",

  WATERMARK_REMOVAL_MODAL_CONFIRM_CLICK: "watermark_removal_modal_confirm_click",
  WATERMARK_REMOVAL_MODAL_CANCEL_CLICK: "watermark_removal_modal_cancel_click",

  COMPONENT_ERROR: "component_error",
  JS_GLOBAL_ERROR: "js_global_error",

  BOUNTY_START_CLICK: "bounty_start_click",
};

export const hits = {
  GET_APP_CLICK: 0,

  PHOTO_SELECT: 0,

  FEED_PAGE_LEARN_MORE_CLICK: 0,
  FEED_PAGE_RECOMMENDED_CLICK: 0,

  PROCESSING_TIMEOUT: 0,
  PROCESSING_FAILED: 0,
  PROCESSING_PROCESSED: 0,
  PROCESSING_FAILED_BY_PHOTOLAB: 0,
  PROCESSING_PAGE_SHOWN: 0,
  PROCESSING_PAGE_LEARN_MORE_CLICK: 0,
  PROCESSING_PAGE_STATUS_PROCESSED: 0,

  MASK_UPDATED: 0,
  PAGE_MASK_AFTER_RESULT: 0,
  PAGE_MASK_BEFORE_PROCESSING: 0,
  SKIP_MASK_AFTER_RESULT: 0,
  SKIP_MASK_BEFORE_PROCESSING: 0,
  SAVE_MASK_AFTER_RESULT: 0,
  SAVE_MASK_BEFORE_PROCESSING: 0,

  START_AUTO_PROCESSING: 0,
  START_PENDING_PROCESSING: 0,

  FRONTEND_WATERMARK_STARTED: 0,
  FRONTEND_WATERMARK_PROCESSED: 0,
  FRONTEND_WATERMARK_FAILED: 0,
  FRONTEND_WATERMARK_FAILED_TIMEOUT: 0,

  RESULT_PAGE_DOWNLOAD_CLICK: 0,

  WATERMARK_REMOVAL_MODAL_CONFIRM_CLICK: 0,
  WATERMARK_REMOVAL_MODAL_CANCEL_CLICK: 0,

  SHARE_MODAL_LEARN_MORE_CLICK: 0,
  SHARE_MODAL_CANCEL_CLICK: 0,
  SHARE_MODAL_SHARE_CLICK: 0,

  COMPONENT_ERROR: 0,
  ANALYTICS_ERROR: 0,

  REQUEST_PHOTOLAB_SIGN_FAIL: 0,
  REQUEST_PHOTOLAB_SIGN_ALT_FAIL: 0,

  FORCE_REDIRECT_PROCESSING: 0,
};

const hitsCache = {};
export function hitEvent(id, count = 1, ignoreUserGroup = false, delay = 1000) {
  const config = window.appConfig.hits;

  if (id === 0) {
    console.warn("Zero-valued hit triggered");
    return;
  }

  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", JSON.stringify({hitName, id, count, ignoreUserGroup}));
  }

  if (!config.isEnabled) {
    return;
  }

  if (ignoreUserGroup || config.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    hitsCache[id] = (hitsCache[id] || 0) + count;
    debounce("hitEvent." + id, delay, () => {
      const c = hitsCache[id] || 1;
      hitsCache[id] = 0;

      window.axios.post(`${config.endpoint}?id=${id}&c=${c}&r=${Date.now()}`)
        .then(() => {/* dummy */})
        .catch(console.error);
    });
  }
}

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};
  eventParams.build_version = window.appConfig.build.version;

  if (window.appConfig.isDebug) {
    console.debug("logEvent", eventId, JSON.stringify(eventParams));
  }

  if (window.appConfig.analytics.isEnabled) {
    const isAllow = !window.appConfig.analytics.overloadModeIsEnabled
      ||
      (window.appConfig.analytics.overloadModeIsEnabled
        && window.appConfig.analytics.overloadModeEvents.includes(eventId))
    ;

    if (isAllow) {
      _logEvent(eventId, eventParams).then(() => {
        cb && cb();
      });
    }
  }
}

function _logEvent(eventId, eventParams) {
  let userParams = undefined;
  if (userPropertiesCommitWaited.length > 0) {
    userParams = {};
    userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
    userPropertiesCommitWaited.length = 0;
  }

  let webviewParams = undefined;
  if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
    webviewParamsIsCommited = true;
    webviewParams = window.clientConfig.webviewParams;
  }

  return axios.post(window.appConfig.analytics.endpoint, {
    client_token: window.clientConfig.token,
    client_params: userParams,
    client_webview_params: webviewParams,
    project_name: window.appConfig.project.name,
    event_name: eventId,
    event_params: eventParams,
    locale: window.clientConfig.locale,
  }).then((response) => {
    return response.data;
  }).then((data) => {
    if (window.clientConfig.geoipCountryCode) {
      return;
    }

    if (data.client) {
      window.clientConfig.geoipCountryCode = data.client.geoip_country_code;
      return;
    }

    try {
      const locale = new Intl.Locale(window.navigator.language);
      window.clientConfig.geoipCountryCode = locale.region || null;
    } catch (e) {/* skip */}
  }).catch((err) => {
    hitEvent(hits.ANALYTICS_ERROR, 1, true, 1);
    console.error(err);
  });
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.debug("setUserProperty", key, value);
  }

  const currentValue = userProperties[key];
  if (currentValue !== value) {
    userProperties[key] = value;
    userPropertiesCommitWaited.push(key);
  }
}

export function logProcessingsTimings(time) {
  if (!window.appConfig.processingTimings.isEnabled) {
    return;
  }

  return axios.post(window.appConfig.processingTimings.endpoint, {
    project_name: window.appConfig.project.name,
    time,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}
