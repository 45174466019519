import styled from "styled-components";

export const WatermarkContainerStyled = styled.div`
  position: absolute;
  z-index: 11;
  width: ${props => props.widthWatermark ? `${props.widthWatermark}px` : "0"};
  height: ${props => props.heightWatermark ? `${props.heightWatermark}px` : "0"};
  top: ${props => props.topWatermark ? `${props.topWatermark}px` : "0"};
  left: ${props => props.leftWatermark ? `${props.leftWatermark}px` : "0"};
`;

export const WatermarkImgStyled = styled.img`
  width: ${props => props.widthWatermarkImg ? `${props.widthWatermarkImg}px` : "0"};
  height: ${props => props.widthWatermarkImg ? `${props.widthWatermarkImg}px` : "0"};
`

export const WatermarkTooltipStyled = styled.div`
  display: ${props => props.hidden ? "none" : "flex"};
  align-items: center;
  position: absolute;
  top: 50%;
  right: 110%;
  transform: translateY(-50%);
  z-index: 15;
`

export const WatermarkTextStyled = styled.p`
  font-size: 12px;
  color: #fff;
  background-color: rgba(34,34,34,.6);
  border-radius: 3px;
  position: relative;
  white-space: nowrap;
  padding: 8px;

  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 7px;
    border-color: transparent transparent transparent rgba(34,34,34,.6);
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
  }
`
