import styled from "styled-components";

export const ErrorMessageStyled = styled.p`
    @keyframes showError {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    position: absolute;
    left: 0;
    top: calc(100% + ${props => props.margin});
    color: red;
    font-size: 12px;
    line-height: 15px;
    color: red;
    animation: showError .3s;
`;