import styled from "styled-components";
import { Link } from "react-router-dom";

export const DefaultLinkStyled = styled(Link)`
  font-family: Alphakind;
  font-size: 1rem;
  line-height: 100%;
  font-weight: 600;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  background-color: ${props => props.primary ? "#ffcb20" : "#d5c6f8"};
  padding: 1.4rem;
  border-radius: 32px;
  border: 1px solid #000;
  box-shadow: 3px 3px 0 0 #000;
  width: 100%;
  text-decoration: none;
  max-width: 200px;
  margin: 0 20px;
  transition: background-color .2s ease;
  margin-top: ${props => props.marginTop}px;

  &:hover {
      background-color: ${props => props.primary ? "#d5c6f8" : "#ffcb20"};
  }
`;
