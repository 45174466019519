import styled from "styled-components";

export const FlexWrapStyled = styled.div`
  width: 100%;
  height: ${props => props.flex ? "100%" : "auto"};
  display: flex;
  flex-direction: ${props => props.direction ? props.direction : "row"};
  justify-content: ${props => props.justify ? props.justify : "flex-start"};
  align-items: ${props => props.align ? props.align : "stretch"};
  gap: ${props => props.gap ? `${props.gap}px` : "0"};;
  text-align: ${props => props.textAlign ? props.textAlign : "left"};
  flex: ${props => props.flex ? props.flex : "none"};
  flex-wrap: ${props => props.wrap ? "wrap" : "nowrap"};
`;
