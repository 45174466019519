import styled from "styled-components";


export const ViewContainerStyled  = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ViewHiddenImageStyled = styled.img`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: -9999px;
  left: -9999px;
`

export const ViewResultImageStyled = styled.img`
  width: ${props => props.widthImage ? `${props.widthImage}px` : "0"};
  height: ${props => props.heightImage ? `${props.heightImage}px` : "0"};
  position: absolute;
  top: ${props => props.topImage ? `${props.topImage}px` : "0"};
  left: ${props => props.leftImage ? `${props.leftImage}px` : "0"};
  border-radius: 32px;
  z-index: 10;
`
