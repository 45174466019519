import React from "react";
import { ButtonStyled } from "./Button.style";

export default class Button extends React.Component {
    render() {
        return (
            <ButtonStyled {...this.props}>{this.props.children}</ButtonStyled>
        )
    }
}
