import React from "react";
import i18n from "../../i18n";
import {assetUrl} from "../../utils/etc";
import Loader from "../Loader/Loader";
import {TOONCOIN_SITE} from "../../utils/constants";
import { LoadingImgStyled, LoadingOverlayStyled } from "./Loading.style";
import Button from "../Button/Button";


export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bannerIsLoaded: false,
    };
  }

  handleLearnMoreButtonClick = (e) => {
      e.preventDefault();
      window.open(TOONCOIN_SITE, "_blank");
  };

  render() {
    return <LoadingOverlayStyled hidden={this.props.hidden}>
      <Loader message={this.props.message} />
      <LoadingImgStyled
        src={assetUrl("assets/images/bg-processing.png")}
        alt=""
        onLoad={() => this.setState({bannerIsLoaded: true})} />
      {this.state.bannerIsLoaded && <Button
        primary
        onClick={this.handleLearnMoreButtonClick}
        dangerouslySetInnerHTML={{__html: i18n.t("processing_page__learn_more")}} />}
    </LoadingOverlayStyled>;
  }
}
