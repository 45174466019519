import styled from "styled-components";

export const MainTitleStyled = styled.h1`
  font-family: Alphakind;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  text-shadow: 3px 3px 0 #000;
  -webkit-text-stroke: 1px #000;
  position: relative;

  span {
    color: ${props => props.primary ? "#d2c7f4" : "#ffcb20"};
  }

  @media (max-height: 640px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
