import styled from "styled-components";

export const  LoadingOverlayStyled = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: ${props => props.hidden ? "none" : "flex"};
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 24px;
  width: 100vw;
  height: 100vh;
  background: #FFFBFF;
`
export const  LoadingImgStyled = styled.img`
  height: 60vh;
  object-fit: contain;
`
