import React from "react";
import AppContext from "../../../contexts/AppContext";
import routes from "../../../routes";
import Container from "../../../components/Container/Container";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import BackButton from "../../../components/BackButton/BackButton";
import MainTitle from "../../../components/MainTitle/MainTitle";
import RewardFormView from "./RewardFormView";
import DefaultLink from "../../../components/DefaultLink/DefaultLink";
import * as api from "../../../utils/api";
import clientStorage from "../../../utils/client-storage";

const viewMode = {
  form: "form",
  accept: "accept",
};

export default class RewardPage extends React.Component {
  state = {
    viewMode: viewMode.form,
  };

  componentDidMount() {
    this.context.hideLoader();
  }

  handleGoToStart = () => {
    this.props.history.push(routes.BOUNTY_INDEX);
  }

  handleFormSubmit = (email, username, walletAddress, link, comment, attachment) => {
    this.context.showLoader();

    api.saveReward(email, username, walletAddress, link, comment, attachment)
      .then((result) => {
        this.context.resetRewardData();
        clientStorage.setClientWalletAddress(walletAddress.trim());

        this.setState({
          viewMode: viewMode.accept,
        }, this.context.hideLoader);
      })
      .catch((err) => {
        this.context.hideLoader(() => {
          const errMessage = err.name === "ApiResponseError"
            ? err.message
            : "Something went wrong. Try again.";

          this.context.updateRewardData({formError: errMessage});
        });
      });
  }

  render() {
    return <Container>
      <FlexWrap direction="column" align="center" gap="24">
        <BackButton onClick={this.handleGoToStart} />
        <MainTitle>claim your <span>reward</span></MainTitle>
        {this.state.viewMode === viewMode.form && <RewardFormView onSubmit={this.handleFormSubmit} />}
        {this.state.viewMode === viewMode.accept && <React.Fragment>
          <h2 style={{ "animation": "showBlock 0.2s" }}>Manager will contact you soon</h2>
          <DefaultLink to={routes.BOUNTY_INDEX} style={{ "animation": "showBlock 0.2s" }}>Go to home</DefaultLink>
        </React.Fragment>}
      </FlexWrap>
    </Container>;
  }
}

RewardPage.contextType = AppContext;
