import Creative from "./Creative";
import creativeGroups from "./config/groups";

export default class Processing {

  static SELECTED_GROUP = "selected_group";
  static EXTRA_CREATED_AT = "created_at";
  static EXTRA_STARTED_AT = "started_at";
  static EXTRA_IS_FORKED = "is_forked";
  static EXTRA_PREVIEWS = "previews";
  static EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED = "pro_watermark_should_be_removed";
  static EXTRA_FIRST_DOWNLOAD_AT = "first_download_at";
  static EXTRA_IG_PROFILE_USERNAME = "ig_profile_username";

  _id = 0;
  _file = null;
  _maskFile = null;
  _groups = [];
  _creatives = [];
  _language = "en";
  _extra = {};
  _tasks = {};

  get id() {
    return this._id;
  }

  get file() {
    return this._file;
  }

  get maskFile() {
    return this._maskFile;
  }

  get language() {
    return this._language;
  }

  /** @returns {Creative[]} */
  get creatives() {
    return this._creatives;
  }

  get groups() {
    return this._groups;
  }

  get extra() {
    return this._extra;
  }

  setId(id) {
    this._id = id;
  }

  setFile(file) {
    this._file = file;
  }

  setMaskFile(file) {
    this._maskFile = file;
  }

  setGroups(groups) {
    this._groups = groups;
  }

  setLanguage(language) {
    this._language = language || "en";
  }

  setTask(key, task) {
    this._tasks[key] = task;
  }

  getTask(key) {
    return this._tasks[key] || null;
  }

  addCreative(creative) {
    creative.setProcessingId(this.id);
    creative.setFileId(this.file.id);

    this.creatives.push(creative);
  }

  replaceCreative(oldCreative, newCreative) {
    newCreative.setProcessingId(this.id);
    newCreative.setFileId(this.file.id);

    const index = this.creatives.findIndex(c => c.id === oldCreative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1, newCreative);
    }
  }

  removeCreative(creative) {
    const index = this.creatives.findIndex(c => c.id === creative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1);
    }
  }

  getCreativesInGroup(group) {
    let creatives = this.creatives.filter((c) => c.inGroup(group));

    return creatives;
  }

  getSelectedCreativeInGroup(group) {
    return this.creatives.find((c) => c.inGroup(group) && c.isSelected);
  }

  getGender() {
    const genderCreative = this.getCreativesInGroup(creativeGroups.COMMON)
      .find((c) => c.templateId === "gender");

    if (genderCreative && genderCreative.isProcessed) {
      return genderCreative.result;
    }

    return "male";
  }

  setExtra(key, value) {
    this._extra[key] = value;
  }

  hasExtra(key) {
    return this._extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this.hasExtra(key)
      ? this._extra[key]
      : defaultValue;
  }

  setPreview(group, url) {
    const previews = this.getExtra(Processing.EXTRA_PREVIEWS) || {};
    previews[group] = url;

    this.setExtra(Processing.EXTRA_PREVIEWS, previews);
  }

  getPreview(group) {
    const previews = this.getExtra(Processing.EXTRA_PREVIEWS) || {};

    return previews[group];
  }

  toJSON(pretty = false) {
    const output = {
      id: this._id,
      file: this._file,
      maskFile: this._maskFile,
      groups: this._groups,
      creatives: this._creatives.map((creative) => creative.data),
      language: this._language,
      extra: this._extra,
      tasks: this._tasks,
    };

    return pretty
      ? JSON.stringify(output, null, 2)
      : JSON.stringify(output);
  }

  fromObject(obj) {
    this._id = obj.id || null;
    this._file = obj.file || null;
    this._maskFile = obj.maskFile || null;
    this._groups = obj.groups || [];
    this._language = obj.language || "en";
    this._extra = obj.extra || {};
    this._tasks = obj.tasks || {};
    this._creatives = (obj.creatives || []).map((data) => {
      const creative = new Creative();
      creative.fromObject(data);

      return creative;
    });
  }
}