export const supportedLanguagePaths = ["de", "en", "es", "fr", "it", "ja", "ko", "pt", "ru", "zh"];

export default {
  INDEX: "/",
  FEED: "/feed",
  // INDEX: "/:lang(" + supportedLanguagePaths.join("|") + ")?",
  UPLOAD: "/upload",
  MASK_EDITOR: "/maskeditor",
  PROCESSING: "/processing/:hash?",
  RESULT: "/result",
  ERROR: "/error",

  BOUNTY_INDEX: "/bounty",
  BOUNTY_TERMS: "/bounty/rules",
  BOUNTY_SIGNUP: "/bounty/signup",
  BOUNTY_CREATE: "/bounty/create",
  BOUNTY_PROCESSING: "/bounty/processing",
  BOUNTY_RESULT: "/bounty/result",
  BOUNTY_REWARDS: "/bounty/reward",
};
