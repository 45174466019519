import React from "react";
import Button from "../../../../components/Button/Button";
import Container from "../../../../components/Container/Container";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import i18n from "../../../../i18n";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import { LandingViewImage } from "./LandingView.style";
import FlexWrap from "../../../../components/FlexWrap/FlexWrap";

export default class LandingView extends React.Component {
  render() {
    const { nextClick, handleGoToReward } = this.props;
    return (
      <Container>
        <FlexWrap direction="column" gap="24">
          <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.index_heading") }} />
          <Paragraph dangerouslySetInnerHTML={{ __html: i18n.t("bounty.index_text") }} />
          <LandingViewImage>
            <img
              src="/assets/images/bounty-main.webp"
              alt="bounty"
            />
          </LandingViewImage>
          <FlexWrap direction="column" gap="16">
            <Paragraph dangerouslySetInnerHTML={{ __html: i18n.t("bounty.index_ready_text") }} />
            <Button onClick={nextClick} primary marginBottom={16}>{i18n.t("bounty.index_start_button")}</Button>
            <Button className="underline" onClick={handleGoToReward}>{i18n.t("bounty.index_reward_button")}</Button>
          </FlexWrap>
        </FlexWrap >
      </Container>
    )
  }
}
