import styled from "styled-components";

export const LoadMoreButtonStyled = styled.button`
  background: none;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0 auto;
`;
