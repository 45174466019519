import React from "react";
import AppContext from "../../../contexts/AppContext";
import SignupFormView from "./SignupFormView";
import routes from "../../../routes";
import clientStorage from "../../../utils/client-storage";
import Container from "../../../components/Container/Container";
import BackButton from "../../../components/BackButton/BackButton";
import MainTitle from "../../../components/MainTitle/MainTitle";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import i18n from "../../../i18n";

export default class SignupPage extends React.Component {
  state = {};

  componentDidMount() {
    this.context.hideLoader();
  }

  handleGoToTerms = () => {
    this.props.history.push(routes.BOUNTY_TERMS);
  }

  handleContactFormSubmit = (values) => {
    clientStorage.setClientEmail(values.email.trim());

    this.context.showLoader(() => {
      this.props.history.push(routes.BOUNTY_CREATE);
    });
  }

  render() {
    return <main className="main-page">
      <Container>
        <FlexWrap direction="column" gap={24} flex={1}>
          <BackButton onClick={this.handleGoToTerms} />
          <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.signup_form_heading") }} />

          <SignupFormView
            backClick={this.handleGoToTerms}
            onSubmit={this.handleContactFormSubmit}
          />
        </FlexWrap>
      </Container>
    </main>;
  }
}

SignupPage.contextType = AppContext;
