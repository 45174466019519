import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export default createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/Helvetica-Regular.woff2");
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/Helvetica-Bold.woff2");
    font-weight: bold;
    font-display: swap;
  }

  @font-face {
    font-family: "Alphakind";
    src: url("/assets/fonts/Alphakind.woff2");
    font-display: swap;
  }

  body {
    font-family: "Helvetica", sans-serif;
    font-weight: 400;
    font-size: 16px;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }


  .-show-loader {
    overflow: hidden;
  }

  main {
    display: flex;
    min-height: var(--window-inner-height);
  }

  button {
    cursor: pointer;
    position: relative;
    border: none;
    padding: 0;
    input {
      opacity: 0;
      height: 0 !important;
      position: absolute;
      padding: 0 !important;
      margin: 0 !important;
    }
    &[disabled] {
      color: #999;
      background-color: #f3f3f3;
    }
  }

  p {
    text-align: center;
    line-height: 1.5;
    color: #333;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-decoration {
    text-decoration: underline;
  }

  form {
    width: 100%;
    flex: 1;
  }

  .result-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    gap: 24px;
    padding: 56px 16px 48px;

    /* .container {
      flex-direction: column;
      display: flex;
      height: 100px;
      gap: 24px;
      padding-top: 24px;
      padding-bottom: 24px;
      box-sizing: border-box;
    } */
  }
`
