import promiseRetry from "promise-retry";

function getProxyUrl(url) {
  return window.appConfig.paths.facebookStaticProxy + "/" + url.replace("https://", "");
}

function defaultHandler(res) {
  const result = res.ok && res.json();

  if (!result || (result.ok === false && result.message === "Something wrong")) {
    const error = new Error("InstagramParseApiResponseError");
    error.name = "InstagramParseApiResponseError";
    error.response = res.clone();

    throw error;
  }

  return result;
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

function getProfileInfo(username) {
  function requestFunc(retry) {
    return fetch(window.appConfig.paths.apiInstagramParse, {
      method: "POST",
      body: JSON.stringify({
        action: "profile",
        user_name: username,
      })
    })
      .catch(networkErrorHandler)
      .then(defaultHandler)
      .then(parseProfileInfo)
      .catch(retry);
  }

  return promiseRetry(requestFunc, { retries: 1, minTimeout: 1000, maxTimeout: 1000 });
}

function parseProfileInfo(response) {
  const info = {
    profile: {
      fullName: "",
      username: "",
      userId: "",
      isPrivate: true,
      followersCount: 0,
      avatar: ""
    },
    feed: {
      max_id: "",
      items: [],
    }
  };

  info.profile.fullName = response.data.user.full_name;
  info.profile.username = response.data.user.username;
  info.profile.userId = response.data.user.id;
  info.profile.isPrivate = response.data.user.is_private;
  info.profile.followersCount = response.data.user.edge_followed_by.count;
  info.profile.avatar = getProxyUrl(response.data.user.profile_pic_url_hd);

  if (info.profile.isPrivate) {
    return info;
  }

  response.data.user.edge_owner_to_timeline_media.edges.forEach((item) => {
    info.feed.max_id = `${item.node.id}_${info.profile.userId}`;

    if (!item.node.is_video) {
      info.feed.items.push({
        ...item.node.dimensions,
        url: getProxyUrl(item.node.display_url),
      });
    }
  });

  if (!response.data.user.edge_owner_to_timeline_media.page_info.has_next_page) {
    info.feed.max_id = null
  }

  return info;
}

function getFeed(userId, maxId) {
  function requestFunc(retry) {
    return fetch(window.appConfig.paths.apiInstagramParse, {
      method: "POST",
      body: JSON.stringify({
        action: "feed",
        user_id: userId,
        max_id: maxId,
      })
    })
      .catch(networkErrorHandler)
      .then(defaultHandler)
      .then(parseFeed)
      .catch(retry);
  }

  return promiseRetry(requestFunc, { retries: 1, minTimeout: 1000, maxTimeout: 1000 });
}

function parseFeed(response) {
  const info = {
    feed: {
      max_id: "",
      items: [],
    }
  };

  info.feed.max_id = response.next_max_id || null;

  response.items.forEach((item) => {
    // media_type === 1 -> image
    // media_type === 2 -> video
    // media_type === 8 -> carousel

    if (item.media_type === 1) {
      const candidate = item.image_versions2.candidates.reduce(function (prev, current) {
        return (prev.width > current.width) ? prev : current;
      });

      info.feed.items.push({
        ...candidate,
        url: getProxyUrl(candidate.url),
      });
    }

    if (item.media_type === 8) {
      item.carousel_media.forEach((carouselItem) => {
        if (carouselItem.media_type === 1) {
          const candidate = carouselItem.image_versions2.candidates.reduce(function (prev, current) {
            return (prev.width > current.width) ? prev : current;
          });

          info.feed.items.push({
            ...candidate,
            url: getProxyUrl(candidate.url),
          });
        }
      });
    }
  });

  return info;
}

function getStories(userId) {
  function requestFunc(retry) {
    return fetch(window.appConfig.paths.apiInstagramParse, {
      method: "POST",
      body: JSON.stringify({
        action: "stories",
        user_id: userId,
      })
    })
      .catch(networkErrorHandler)
      .then(defaultHandler)
      .then(parseStories)
      .catch(retry);
  }

  return promiseRetry(requestFunc, { retries: 1, minTimeout: 1000, maxTimeout: 1000 });
}

function parseStories(response) {
  const info = {
    stories: {
      items: [],
    }
  };

  response.reels_media.forEach((media) => {
    media.items.forEach((item) => {
      if (item.product_type === "story" && item.media_type === 1) {
        const candidate = item.image_versions2.candidates.reduce(function (prev, current) {
          return (prev.width > current.width) ? prev : current;
        });

        info.stories.items.push({
          ...candidate,
          url: getProxyUrl(candidate.url),
        });
      }
    });
  });

  return info;
}

export default {
  getProfileInfo,
  getFeed,
  getStories,
};
