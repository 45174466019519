import styled from "styled-components";

export const ModalContainerStyled = styled.div`
  box-sizing: border-box;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: var(--window-inner-height);
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.75);
`

export const ModalBtnCloseStyled = styled.button`
  position: absolute;
  top: 8px;
  right: 12px;
  width: 26px;
  height: 26px;
  padding: 4px;
  box-sizing: border-box;
  background: none;
`

export const ModalIconBtnCloseStyled = styled.svg`
  width: 100%;
  height: 100%;
  fill: #fff;
`