const VERSION = 1;

const KEY_PREFS = "tw:prefs";
const KEY_PREFS_VERSION = "tw:prefs:version";

const KEY_CLIENT_TOKEN = "client_token";
const KEY_CLIENT_EMAIL = "client_email";
const KEY_CLIENT_WALLET_ADDRESS = "client_wallet_address";
const KEY_SPLIT_GROUP_ID = "split_group_id";
const KEY_WEBVIEW_REQUEST_PARAMS = "webview_request_params";
const KEY_PRO_WATERMARK_SHOW_MODAL_INCREMENT = "pro_watermark_show_modal_increment";
const KEY_FIRST_VISIT_AT = "first_visit_at";
const KEY_FIRST_PROCESSING_PROCESSED_AT = "first_processing_processed_at";
const KEY_FIRST_DOWNLOAD_AT = "first_download_at";
const KEY_SHOW_FULL_SIZE_PROMOTION_COUNTER = "show_full_size_promotion_counter";
const KEY_COUNT_PROCESSIN_IN_SESSION = "count_processing_in_session";
const KEY_PROCESSED_PHOTOS_AMOUNT = "processed_photos_amount";
const KEY_SELECTED_LANG = "selected_lang";
const KEY_LATEST_SELECTED_COMBO = "latest_selected_combo";
const KEY_WATERMARK_POSITION = "watermark_position";
const KEY_INSTAGRAM_PROFILES = "instagram_profiles";

class ClientStorage {

  constructor() {
    const prefs = window.localStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }

    const currentVersion = parseInt(window.localStorage.getItem(KEY_PREFS_VERSION) || 1);
    if (currentVersion !== VERSION) {
      this.migrate(currentVersion, VERSION);
      window.localStorage.setItem(KEY_PREFS_VERSION, "" + VERSION);
    }
  }

  migrate(fromVersion, toVersion) {
    // ...
  }

  commit() {
    window.localStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---

  getClientToken() {
    return this.getString(KEY_CLIENT_TOKEN);
  }

  setClientToken(value) {
    this.setString(KEY_CLIENT_TOKEN, value);
  }

  getFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT);
  }

  setFirstVisitAt(value) {
    this.setInteger(KEY_FIRST_VISIT_AT, value);
  }

  hasFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT, Number.MIN_VALUE) !== Number.MIN_VALUE;
  }

  setFirstProcessingProcessedAt(value) {
    this.setInteger(KEY_FIRST_PROCESSING_PROCESSED_AT, value);
  }

  hasFirstProcessingProcessedAt() {
    return this.getInteger(KEY_FIRST_PROCESSING_PROCESSED_AT, Number.MIN_VALUE) !== Number.MIN_VALUE;
  }

  setWebviewRequestParams(value) {
    if (typeof value !== 'object' || value === null) {
      value = {};
    }

    this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] = value;
    this.commit();
  }

  getWebviewRequestParams() {
    if (this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] === undefined) {
      return {};
    }

    return this.prefs[KEY_WEBVIEW_REQUEST_PARAMS];
  }

  getSplitGroupId() {
    return this.getInteger(KEY_SPLIT_GROUP_ID, undefined);
  }

  setSplitGroupId(value) {
    this.setInteger(KEY_SPLIT_GROUP_ID, value);
  }

  getProWatermarkShowModal() {
    return this.getInteger(KEY_PRO_WATERMARK_SHOW_MODAL_INCREMENT, 0);
  }

  getFirstDownloadAt() {
    return this.getInteger(KEY_FIRST_DOWNLOAD_AT);
  }

  getShowFullSizePromotionCounter() {
    return this.getInteger(KEY_SHOW_FULL_SIZE_PROMOTION_COUNTER, 0);
  }

  setCountProcessingInSession(sessionId, count) {
    this.setString(KEY_COUNT_PROCESSIN_IN_SESSION, `${sessionId}:${count}`);
  }

  getCountProcessingInSession() {
    return this.getString(KEY_COUNT_PROCESSIN_IN_SESSION, "-1:0")
      .split(":")
      .map((item) => parseInt(item));
  }

  getProcessedPhotosAmount() {
    return this.getInteger(KEY_PROCESSED_PHOTOS_AMOUNT, 0);
  }

  incrementProcessedPhotosAmount() {
    this.setInteger(
      KEY_PROCESSED_PHOTOS_AMOUNT,
      this.getInteger(KEY_PROCESSED_PHOTOS_AMOUNT, 0) + 1
    );
  }

  getSelectedLang() {
    return this.getString(KEY_SELECTED_LANG, null);
  }

  setSelectedLang(value) {
    this.setString(KEY_SELECTED_LANG, value);
  }

  setLatestSelectedCombo(value) {
    this.prefs[KEY_LATEST_SELECTED_COMBO] = value;
    this.commit();
  }

  getLatestSelectedCombo() {
    return this.prefs[KEY_LATEST_SELECTED_COMBO];
  }

  setWatermarkPosition(x=0, y=0, imageWidth=0, imageHeight=0, storedAt=0) {
    this.prefs[KEY_WATERMARK_POSITION] = {x, y, imageWidth, imageHeight, storedAt};
    this.commit();
  }

  getWatermarkPosition() {
    return this.prefs[KEY_WATERMARK_POSITION]
      || {x: 0, y: 0, imageWidth: 0, imageHeight: 0, storedAt: 0};
  }

  getClientEmail() {
    return this.getString(KEY_CLIENT_EMAIL, "");
  }

  setClientEmail(value) {
    this.setString(KEY_CLIENT_EMAIL, value);
  }

  getClientWalletAddress() {
    return this.getString(KEY_CLIENT_WALLET_ADDRESS, "");
  }

  setClientWalletAddress(value) {
    this.setString(KEY_CLIENT_WALLET_ADDRESS, value);
  }


  setInstagramProfiles(values) {
    this.prefs[KEY_INSTAGRAM_PROFILES] = {
      items: values || [],
      created_at: Date.now(),
    };
    this.commit();
  }

  getInstagramProfiles() {
    return this.prefs[KEY_INSTAGRAM_PROFILES] || {
      items: [],
      created_at: 0,
    };
  }
}

export default new ClientStorage();
