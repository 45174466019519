import styled from "styled-components";

export const ContainerStyled = styled.div`
  display: ${props => props.direction ? "flex" : ""};
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justify};
  flex: 1;
  max-width: 1016px;
  width: 100%;
  padding: 56px 16px 48px;
  margin: 0 auto;

  @media all and (max-width: 540px) {
    padding: 56px 16px 48px;
  }

  .back-btn {
    background: none;
    align-self: flex-start;
  }
`;
