import React from "react";
import { LoaderContainerStyled, LoaderTextIosStyled, LoaderTextAndroidStyled, LoaderIosContainerStyled, LoaderIosStyled, LoaderItemDefaultIosStyled, LoaderItemSecondIosStyled, LoaderItemThirdIosStyled, LoaderAndroidStyled, LoaderItemSecondAndroidStyled, LoaderItemFirtsAndroidStyled } from "./Loader.style";

export default function Loader({hidden, message}) {
  // используем дизайн для ios
  if (window.clientConfig.isWebviewIOS || true) {
    return <IosLoader hidden={hidden} message={message} />
  } else {
    return <AndroidLoader hidden={hidden} message={message} />
  }
}

function IosLoader({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <LoaderContainerStyled ios>
    <LoaderIosContainerStyled>
      <LoaderIosStyled>
        <LoaderItemDefaultIosStyled />
        <LoaderItemSecondIosStyled />
        <LoaderItemThirdIosStyled />
      </LoaderIosStyled>
      <LoaderTextIosStyled children={message} />
    </LoaderIosContainerStyled>
  </LoaderContainerStyled>;
}

function AndroidLoader({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <LoaderContainerStyled android>
    <LoaderTextAndroidStyled children={message} />
    <LoaderAndroidStyled>
      <LoaderItemFirtsAndroidStyled />
      <LoaderItemSecondAndroidStyled />
    </LoaderAndroidStyled>
  </LoaderContainerStyled>;
}