import styled from "styled-components";

export const ErrorViewStyled = styled.main`
  position: relative;
  height: 100vh;
`

export const ErrorViewContainerStyled = styled.div`
  color: #333;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const ErrorViewTitleStyled = styled.h2`
  font-size: 22px;
  font-weight: 700;
  color: #48454e;
  line-height: 27px;
  margin-bottom: 2px;
`

export const ErrorViewTextStyled = styled.p`
  font-size: 16px;
  color: #48454e;
  line-height: 27px;
  margin-bottom: 40px;
`

export const ErrorViewIconStyled = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 25px;
  
  svg {
    fill: #48454e;
  }
`
