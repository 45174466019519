import React from "react";
import AppContext from "../../../contexts/AppContext";
import routes from "../../../routes";
import { logEvent, userEvents } from "../../../utils/log";
import LandingView from "./LandingView/LandingView";

export default class IndexPage extends React.Component {
  state = {};

  componentDidMount() {
    this.context.hideLoader();
  }

  handleGoToTerms = () => {
    logEvent(userEvents.BOUNTY_START_CLICK);

    this.props.history.push(routes.BOUNTY_TERMS);
  }

  handleGoToReward = () => {
    this.props.history.push(routes.BOUNTY_REWARDS);
  }

  render() {
    return <main className="main-page">
      <LandingView
        nextClick={this.handleGoToTerms}
        handleGoToReward={this.handleGoToReward}
      />
    </main>;
  }
}

IndexPage.contextType = AppContext;
