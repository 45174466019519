import styled from "styled-components";

export const Tip = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: #f2f0f9;

  p {
    font-size: 12px;
    line-height: 18px;

    &.bold {
      font-weight: bold;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
`;

export const ListItem = styled.li`
  font-size: 16px;
  line-height: 1.5;
  color: #333;

  &:before {
    content: "";
    display: inline-block;
    width: 100%;
    align-self: flex-start;
    margin-top: 7px;
    margin-right: 16px;
    max-width: 8px;
    height: 8px;
    box-shadow: 2px 2px 0 0 #000;
    border: solid 1px #000;
    background-color: #d2c7f4;
    border-radius: 50%;
  }

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }
`;
