import React from "react";
import { BackButtonStyled } from "./BackButton.style";

export default class BackButton extends React.Component {
  state = {
    scroll: 0
  }

  handleScroll = () => {
    this.setState({ scroll: window.scrollY });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { scroll } = this.state;

    return (
      <BackButtonStyled type="button" className={scroll > 80 ? "scroll" : ""} {...this.props}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="m3.8 7 5.6-5.6L8 0 0 8l8 8 1.4-1.4L3.8 9H16V7z" fill="#000" fillRule="evenodd" />
        </svg>
      </BackButtonStyled>
    )
  }
}
