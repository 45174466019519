import styled from "styled-components";

export const InputStyled = styled.input`
    padding: 12px 16px;
    width: 100%;
    border-radius: 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #000;
    caret-color: #ffcb20;

    &:invalid {
        border-color: red;
    }

    &[type=checkbox], &[type=file] {
      position: absolute;
      opacity: 0;
      z-index: -1;
      visibility: hidden;
    }

    ${props => props.visible &&
      `&[type=file] {
        position: static;
        opacity: 1;
        z-index: 0;
        visibility: visible;
        color: #000;

        &::file-selector-button {
          font-size: 16px;
          font-weight: 600;
          color: #000;
          margin-right: 20px;
          border: none;
          background-color: #ffcb20;
          padding: 12px 16px;
          border-radius: 12px;
          border: 1px solid #000;
          box-shadow: #000 3px 3px 0px 0px;
          cursor: pointer;
          transition: background-color .2s ease;
        }

        &::file-selector-button:hover {
          background-color: #d5c6f8;
        }
      }`
    }
`;
