import React from "react";
import AppContext from "../../../contexts/AppContext";
import routes from "../../../routes";
import TermsView from "./TermsView/TermsView";

export default class TermsPage extends React.Component {
  state = {};

  componentDidMount() {
    this.context.hideLoader();
  }

  handleGoToSignup = () => {
    this.props.history.push(routes.BOUNTY_SIGNUP);
  }

  handleGoToStart = () => {
    this.props.history.push(routes.BOUNTY_INDEX);
  }

  render() {
    return <main className="main-page">
      <TermsView
        backClick={this.handleGoToStart}
        nextClick={this.handleGoToSignup}
      />
    </main>;
  }
}

TermsPage.contextType = AppContext;
