import styled from "styled-components";

export const LoaderContainerStyled = styled.div`
  display: flex;
  align-items: ${props => props.ios ? "center" : "flex-start;"};
  justify-content: center;
  flex-direction: ${props => props.ios ? "row" : "column"};
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 110;
`

export const LoaderIosContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const LoaderIosStyled = styled.div`
  display: flex;
  margin-bottom: 12px;
`

export const LoaderItemDefaultIosStyled = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  animation: item-loader .9s linear infinite;
  background-color: #5f4da7;
  opacity: 1;
  box-shadow: 1px 1px #000;
  margin: 0 6px;

  @keyframes item-loader {
    0% {
      -webkit-transform: scale(1,1);
      transform: scale(1,1);
      opacity: 0
    }

    80% {
      -webkit-transform: scale(1.8,1.8);
      transform: scale(1.8,1.8);
      opacity: 1
    }
  }
`

export const LoaderItemSecondIosStyled = styled(LoaderItemDefaultIosStyled)`
    background-color: #ffcb20;
    animation-delay: .3s;
`

export const LoaderItemThirdIosStyled = styled(LoaderItemDefaultIosStyled)`
    background-color: #ff8db0;
    animation-delay: .6s;
`

export const LoaderTextIosStyled = styled.p`
  font-size: 16px;
  color: #999;
`

export const LoaderAndroidStyled = styled.div`
  width: 200px;
  height: 2px;
  position: relative;
  overflow: hidden;
`

export const LoaderItemFirtsAndroidStyled = styled.span`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #6250AA;
  width: 100%;
  opacity: .24;
`

export const LoaderItemSecondAndroidStyled = styled.span`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #6250AA;
  width: 70px;
  animation: line-loader .9s linear infinite;

  @keyframes line-loader {
    0% {
      left: -70px
    }

    100% {
      left: calc(100% + 70px)
    }
  }
`

export const LoaderTextAndroidStyled = styled.p`
  font-size: 14px;
  color: #79757F;
  margin-bottom: 12px;
`

export const LoaderImgStyled = styled.img``
