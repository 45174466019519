import styled from "styled-components";

export const ConfigViewColumnsStyled = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  max-width: 540px;
  margin: 24px auto 0;
`

export const ConfigViewColumnStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
`;

export const ConfigViewBtnStyled = styled.button`
  width: 100%;
  padding-top: ${props => props.$height ? `${props.$height}%` : "100%"};
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid;
  border-color: ${props => props.$activeStyle ? "red" : "transparent"};
  position: relative;
`
export const ConfigViewImgStyled = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
