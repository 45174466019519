import React from "react";
import clientStorage from "../utils/client-storage";

const AppContext = React.createContext({
  loader: {},
  installedApps: {},
  modals: [],
});

const AppContextConsumer = AppContext.Consumer;

const defaultRewardData = () => {
  return {
    formError: null,
    email: {
      value: clientStorage.getClientEmail(),
      error: null,
    },
    walletAddress: {
      value: clientStorage.getClientWalletAddress(),
      error: null,
    },
    username: {
      value: "",
      error: null,
    },
    link: {
      value: "",
      error: null,
    },
    comment: {
      value: "",
      error: null,
    },
    attachment: {
      value: null,
      error: null,
    },
  }
};

class AppContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loader: {
        hidden: true,
        message: "",
      },
      signupData: {
        email: {
          value: clientStorage.getClientEmail(),
          error: null,
        },
        confirm: {
          value: !!clientStorage.getClientEmail(),
          error: null,
        }
      },
      rewardData: defaultRewardData(),
      installedApps: {},
      modals: [],
    };
  }

  render() {
    const state = {
      ...this.state,
      setInstalledApps: (apps) => {
        this.setState({installedApps: apps});
      },
      pushModal: (component, cb) => {
        const modals = this.state.modals.slice();
        modals.push(component);

        this.setState({modals}, () => cb && cb());
      },
      popModal: (cb) => {
        const modals = this.state.modals.slice();
        modals.pop();

        this.setState({modals}, () => cb && cb());
      },
      hideLoader: (cb) => {
        document.body.classList.remove("-show-loader");

        this.setState({loader: {
          hidden: true,
        }},() => cb && cb());
      },
      showLoader: (cb) => {
        document.body.classList.add("-show-loader");

        this.setState({loader: {
          hidden: false,
        }},() => cb && cb());
      },
      updateSignupData: (data, cb) => {
        this.setState({
          signupData: {
            ...this.state.signupData,
            ...data,
          }
        }, () => cb && cb());
      },
      updateRewardData: (data, cb) => {
        this.setState({
          rewardData: {
            ...this.state.rewardData,
            ...data,
          }
        }, () => cb && cb());
      },
      resetRewardData: (data, cb) => {
        this.setState({
          rewardData: defaultRewardData(),
        }, () => cb && cb());
      },
    };

    return <AppContext.Provider
      value={state}
      children={this.props.children}
    />;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
