import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../i18n";
import Modal from "../../../components/Modal/Modal";
import {hitEvent, hits, logEvent, userEvents} from "../../../utils/log";

export default class WatermarkRemovalModal extends Modal {

  constructor(props) {
    super(props);
    this.className = "watermark-remove-modal";
  }

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleConfirmButtonClick = () => {
    hitEvent(hits.WATERMARK_REMOVAL_MODAL_CONFIRM_CLICK);
    logEvent(userEvents.WATERMARK_REMOVAL_MODAL_CONFIRM_CLICK);

    this.props.onConfirm();
    this.dismiss();
  }

  handleCancelButtonClick = () => {
    hitEvent(hits.WATERMARK_REMOVAL_MODAL_CANCEL_CLICK);
    logEvent(userEvents.WATERMARK_REMOVAL_MODAL_CANCEL_CLICK);

    this.props.onCancel();
    this.dismiss();
  }

  renderIos = () => {
    return <React.Fragment>
      <div className="popup-block">
        <h2 className="popup-title" dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__text1")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__text2")}} />
      <div className="notice">
        <SvgNoticeIcon />
        <p dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__notice")}} />
      </div>
      </div>
      <div className="popup-block">
        <h2 className="popup-title" dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__choice")}} />
        <button className="btn-remove-wm" onClick={this.handleConfirmButtonClick} dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__confirm")}} />
        <button className="btn-spread-wm" onClick={this.handleCancelButtonClick} dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__cancel")}} />
      </div>
      <button className="btn-close-popup" onClick={this.handleCancelButtonClick}>
        <SvgCloseIcon />
      </button>
    </React.Fragment>
  }

  renderAndroid = () => {
    return <React.Fragment>
      <div className="popup-block">
        <h2 className="popup-title" dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__text1")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__text2")}} />
        <div className="notice">
          <SvgNoticeIcon />
          <p dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__notice")}} />
        </div>

        <h2 className="popup-title popup-title-default" dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__choice")}} />
        <button className="btn-remove-wm" onClick={this.handleConfirmButtonClick} dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__confirm")}} />
        <button className="btn-spread-wm" onClick={this.handleCancelButtonClick} dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__cancel")}} />
      </div>
      <button className="btn-close-popup" onClick={this.handleCancelButtonClick} dangerouslySetInnerHTML={{__html: i18n.t("watermark_removal_modal__close")}} />
    </React.Fragment>
  }

  renderModal() {
    return window.clientConfig.isWebviewAndroid
      ? this.renderAndroid()
      : this.renderIos();
  }
}

WatermarkRemovalModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function SvgNoticeIcon() {
  return <svg viewBox="0 0 38 53">
    <defs>
      <filter x="-7.6%" y="-6.9%" width="124.2%" height="122.2%" filterUnits="objectBoundingBox" id="rq50uqow6a">
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="3" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" in="shadowOffsetOuter1"/>
      </filter>
      <filter x="-17.4%" y="-117.2%" width="155.8%" height="475%" filterUnits="objectBoundingBox" id="x4bdos4nnc">
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="3" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" in="shadowOffsetOuter1"/>
      </filter>
      <filter x="-17.4%" y="-117.2%" width="155.8%" height="475%" filterUnits="objectBoundingBox" id="d6v15kqmse">
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="3" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" in="shadowOffsetOuter1"/>
      </filter>
      <filter x="-22%" y="-117.2%" width="170.3%" height="475%" filterUnits="objectBoundingBox" id="y00v2wiqag">
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="3" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" in="shadowOffsetOuter1"/>
      </filter>
      <path d="M22.573 36.092H10.426c-.606 0-1.097-.477-1.097-1.066v-.358c0-2.328-1.116-4.523-3.062-6.021C1.984 25.348-.344 20.207.041 14.89.62 6.921 7.238.534 15.437.033c4.617-.283 9.001 1.252 12.357 4.317C31.102 7.374 33 11.64 33 16.054c0 4.992-2.327 9.614-6.383 12.68a7.474 7.474 0 0 0-2.947 5.933v.36c0 .588-.491 1.065-1.097 1.065z" id="2v3sv5zwlb"/>
      <path d="M22.573 40.062H10.426c-.606 0-1.097-.477-1.097-1.067 0-.589.49-1.066 1.097-1.066h12.147c.606 0 1.097.477 1.097 1.066 0 .589-.491 1.067-1.097 1.067z" id="lx43jqjuqd"/>
      <path d="M22.573 44.031H10.426c-.606 0-1.097-.477-1.097-1.067 0-.589.49-1.066 1.097-1.066h12.147c.606 0 1.097.477 1.097 1.066 0 .59-.491 1.067-1.097 1.067z" id="ve40jacd4f"/>
      <path d="M21.094 48h-9.19c-.605 0-1.096-.477-1.096-1.067 0-.588.49-1.066 1.097-1.066h9.19c.605 0 1.096.477 1.096 1.066 0 .59-.491 1.067-1.097 1.067z" id="spgi0gc3ah"/>
    </defs>
    <g fillRule="nonzero" fill="none">
      <g transform="translate(1 1)">
        <use fill="#000" filter="url(#rq50uqow6a)" xlinkHref="#2v3sv5zwlb"/>
        <path stroke="#000" d="M15.406-.466c4.755-.292 9.27 1.29 12.725 4.447 3.413 3.12 5.369 7.52 5.369 12.073 0 5.148-2.397 9.916-6.582 13.08a6.972 6.972 0 0 0-2.748 5.533c0 .59-.162 1.032-.398 1.34-.3.388-.725.585-1.199.585H10.426c-.44 0-.837-.174-1.124-.454a1.547 1.547 0 0 1-.473-1.112c0-2.43-.959-4.412-2.651-5.811-4.645-3.59-7.032-8.887-6.635-14.36.298-4.11 2.152-7.814 4.99-10.553A17.146 17.146 0 0 1 15.407-.466z" fill="#FFCB20" fillRule="evenodd"/>
      </g>
      <g transform="translate(1 1)">
        <use fill="#000" filter="url(#x4bdos4nnc)" xlinkHref="#lx43jqjuqd"/>
        <path stroke="#000" d="M22.573 37.429c.44 0 .837.174 1.124.454a1.547 1.547 0 0 1 0 2.225c-.287.28-.685.454-1.124.454H10.426c-.44 0-.837-.174-1.124-.454a1.547 1.547 0 0 1 0-2.225c.287-.28.684-.454 1.124-.454z" fill="#FFCB20" fillRule="evenodd"/>
      </g>
      <g transform="translate(1 1)">
        <use fill="#000" filter="url(#d6v15kqmse)" xlinkHref="#ve40jacd4f"/>
        <path stroke="#000" d="M22.573 41.398c.44 0 .837.174 1.124.454a1.547 1.547 0 0 1 0 2.225c-.287.28-.685.454-1.124.454H10.426c-.44 0-.837-.174-1.124-.454a1.547 1.547 0 0 1 0-2.225c.287-.28.684-.454 1.124-.454z" fill="#FFCB20" fillRule="evenodd"/>
      </g>
      <g transform="translate(1 1)">
        <use fill="#000" filter="url(#y00v2wiqag)" xlinkHref="#spgi0gc3ah"/>
        <path stroke="#000" d="M21.094 45.367c.44 0 .837.174 1.124.454a1.547 1.547 0 0 1 0 2.225c-.287.28-.684.454-1.124.454h-9.19c-.438 0-.836-.174-1.123-.454a1.547 1.547 0 0 1 0-2.225c.287-.28.684-.454 1.124-.454z" fill="#FFCB20" fillRule="evenodd"/>
      </g>
    </g>
  </svg>;
}

function SvgCloseIcon() {
  return <svg viewBox="0 0 48 48">
    <g stroke="#FFF" strokeWidth="6" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M45 3 3 45M45 45 3 3"/>
    </g>
  </svg>;
}
