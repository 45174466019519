import React from "react";
import { ContainerStyled } from "./Container.style";

export default class Container extends React.Component {
  render() {
    return (
      <ContainerStyled>
        {this.props.children}
      </ContainerStyled>
    )
  }
}
