import React from "react";
import { ErrorMessageStyled } from "./ErrorMessage.style";

export default class ErrorMessage extends React.Component {
    render() {
        const { margin } = this.props;

        return (
            <ErrorMessageStyled margin={margin} >{this.props.children}</ErrorMessageStyled>
        )
    }
}