import React from "react";
import { FeedViewItemStyled, FeedViewStyled } from "./FeedView.style";

export default class FeedView extends React.Component {
  state = {
    size: 0,
    selected: null
  }

  handleImageResize = () => {
    const { width } = document.querySelector('.image-container').getBoundingClientRect();
    const imageSize = (width / 3) - (4 / 3);
    this.setState({ size: imageSize });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleImageResize);
    this.handleImageResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleImageResize);
  }

  handleClick = (image) => {
    this.props.onSelected(image);
    this.setState({ selected: image.url });
  }


  render() {
    const { size, selected } = this.state;

    return <FeedViewStyled className="image-container" align='flex-start' gap={2} wrap={1} flex={1}>
      {this.props.feed.map((item) => (
        <FeedViewItemStyled
          className={selected ? (selected === item.url ? 'active' : 'disabled') : ''}
          size={size}
          onClick={() => this.handleClick(item)} key={item.url}
        >
          <img src={item.url} alt="bounty" />
        </FeedViewItemStyled>
      ))}
    </FeedViewStyled>;
  }
}

function ItemView(props) {
  const {
    image,
    isActive,
    onClick,
  } = props;

  const classNames = ["image-item"];
  isActive && classNames.push("image-item--active");

  return <div
    className={classNames.join(" ")}
    style={isActive ? { border: "solid red" } : {}}
    onClick={() => onClick(image)}>
    <img src={image.url} alt="." />
  </div>;
}

