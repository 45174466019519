import React from "react";
import { ParagraphStyled } from "./Paragraph.style";

export default class Paragraph extends React.Component {
  render() {
    return (
      <ParagraphStyled {...this.props}>{this.props.children}</ParagraphStyled>
    )
  }
}
