import React from "react";
import AppContext from "../../../contexts/AppContext";
import i18n from "../../../i18n";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import Label from "../../../components/Label/Label";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export default class SignupFormView extends React.Component {
  handleFormSubmit = (e) => {
    e.preventDefault();

    const email = this.context.signupData.email.value.trim();

    if (!isValidEmail(email)) {
      this.context.updateSignupData({
        email: {
          value: this.context.signupData.email.value,
          error: i18n.t("bounty.signup_form_email_is_invalid"),
        }
      });

      return;
    }

    if (!this.context.signupData.confirm.value) {
      this.context.updateSignupData({
        confirm: {
          value: this.context.signupData.confirm.value,
          error: i18n.t("bounty.signup_form_field_required"),
        }
      });

      return;
    }

    this.props.onSubmit({ email });
  }

  handleEmailChange = (e) => {
    this.context.updateSignupData({
      email: {
        value: e.target.value,
        error: null,
      }
    });
  }

  handleConfirmChange = (e) => {
    this.context.updateSignupData({
      confirm: {
        value: !this.context.signupData.confirm.value,
        error: null,
      }
    });
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    const emailField = this.context.signupData.email;
    const confirmField = this.context.signupData.confirm;

    return <React.Fragment>
      <form onSubmit={this.handleFormSubmit} noValidate>
        <FlexWrap direction="column" gap={24} flex={1}>
          <FlexWrap direction="column" gap={24} flex={1}>
            <Label>
              {i18n.t("bounty.signup_form_email_label")}
              <Input
                type="email"
                placeholder="email"
                value={emailField.value}
                onChange={this.handleEmailChange}
              />
              {emailField.error && <div className="error-message">{emailField.error}</div>}
            </Label>
          </FlexWrap>
          <FlexWrap direction="column" gap={24}>
            <Label large className={`checkbox ${confirmField.value ? "active" : ""}`}>
              <Input type="checkbox" checked={confirmField.value} onChange={this.handleConfirmChange} />
              <span>I agree to the <button type="button" onClick={this.props.backClick}>terms and conditions</button> of the program.</span>
              {confirmField.error && <div className="error-message">{confirmField.error}</div>}
            </Label>
            <Button type="submit" primary>
              {i18n.t("bounty.signup_form_submit_button")}
            </Button>
          </FlexWrap>
        </FlexWrap>
      </form>
    </React.Fragment>;
  }
}

SignupFormView.contextType = AppContext;
