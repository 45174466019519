import React from "react";
import { DefaultLinkStyled } from "./DefaultLink.style";

export default class DefaultLink extends React.Component {
    render() {
        return (
            <DefaultLinkStyled {...this.props}>{this.props.children}</DefaultLinkStyled>
        )
    }
}
