import React from "react"
import { TextareaStyled } from "./Textarea.style"

export default class Textarea extends React.Component {
    render() {
        return (
            <TextareaStyled {...this.props} />
        )
    }
}
